import mixpanel from 'mixpanel-browser';

mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_ID!, { debug: false });

export type MixPanelContractEvent = {
    Status: 'Success' | 'Failure';
    'Transaction hash'?: string;
    'Explorer URL'?: string;
    Amount?: number;
    Token?: string;
    Network?: string;
};

export type MIX_INDEX_CARD_BUTTON_EVENT = `CARD-BUTTON-${string}`;
export type MIX_STAKE_SWAP_EVENT = `SWAP-${string}`;
export type MIX_STAKE_TAB_EVENT = `SWITCH-TAB-${string}`;

export type MixPanelEventsMap = {
    'NAV-LOGO': {};
    'NAV-ELEMENTS 1': {};
    'NAV-ELEMENTS 2': {};
    'NAV-ELEMENTS 3': {};
    'NAV-BUTTON 1': {};
    'NAV-BUTTON 2': {};
    'NAV-ICON-HAMBURGER': {};
    'FOOTER-LINK1': {};
    'FOOTER-LINK2': {};
    'LANGUAGE SELECTOR': {};
    'WITHDRAW-TYPE-FLASH': {};
    'WITHDRAW-TYPE-NORMAL': {};
    'STAKE-PRIMARY-UNITINPUT': {};
    'STAKE-PRIMARY-UNITINPUT-MAX': {};
    'STAKE-SECONDARY-UNITINPUT': {};
    'REWARD-CALCULATOR-INPUT': {};
    'TRANSACTION-MODAL-CLOSE-ICON': {};
    'TRANSACTION-MODAL-CLOSE-BUTTON': {};
    'MODAL-CLOSE-BUTTON-ICON': {};
    'TRANSACTION-MODAL-VIEW-EXPLORER': {};
    'TRANSACTION-MODAL-ADD-TO-METAMASK': {};
    'PRICE-CHART-TIME-SELECTOR': { key: string };
    'PRICE-CHART-TIME-ADD-ASSET': { token: string };
    MIX_INDEX_CARD_BUTTON_EVENT: {};
    'Select theme': { Theme?: string };
    'Connect wallet': { 'Wallet name'?: string; Network?: string };
    'Allow token': Omit<MixPanelContractEvent, 'Amount'>;
    'Stake token': MixPanelContractEvent;
    'Unstake token': MixPanelContractEvent;
    'Claim token': MixPanelContractEvent;
    'Flash exit': MixPanelContractEvent;
    'HELP-CENTER-OPEN': {};
};
export type MixPanelEvents = keyof MixPanelEventsMap;

export const useMixpanel = () => {
    const track = <K extends MixPanelEvents>(
        key: K | MIX_INDEX_CARD_BUTTON_EVENT | MIX_STAKE_SWAP_EVENT | MIX_STAKE_TAB_EVENT | string,
        params?: MixPanelEventsMap[K],
    ) => {
        mixpanel.track(key, params);
    };
    return {
        track,
        mixpanel,
    };
};
