import classNames from 'classnames';
import { useRouter } from 'next/router';
import { FC } from 'react';
import { Anchor } from './atom/Anchor';

export interface AppNavProps {}

export const StakeNav: FC<AppNavProps> = () => {
    const router = useRouter();
    const navItemStyling = 'hidden nav__item_exchange--flat lg:inline-block';
    return (
        <div className="flex items-center justify-center gap-4 ml-auto">
            <Anchor
                className={classNames(navItemStyling, { '!text-main-30': router.pathname === '/' })}
                caption={'Stake'}
                href="/"
                id="stake-btn"
                onClick={() => {}}
            />
            <Anchor
                className={classNames(navItemStyling, { '!text-main-30': router.pathname === '/exchange' })}
                caption={'Exchange'}
                href="/exchange"
                id="exchange-btn"
                onClick={() => {}}
                isNew
            />
            <Anchor
                className={classNames(navItemStyling, { '!text-main-30': router.pathname === '/market' })}
                caption={'Market'}
                href="/market"
                id="market-btn"
                onClick={() => {}}
                isNew
            />
        </div>
    );
};
