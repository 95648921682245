import { EXCHANGE_TOKENS } from '@/constants/exchange-tokens';
import { GAME_CONFIG } from '@/constants/game-config';
import { TOKEN_CONFIG } from '@/constants/tokens';
import { ContractInstance } from '@/constants/types';
import { ethers } from 'ethers';

export const getContract = async (info: ContractInstance) => {
    try {
        if (!info.network || !info.token) return null;

        const abi = (await TOKEN_CONFIG[info.token]!.abi[info.network]![info.contractName]!()).default;
        const address = getAddress(info);
        return new ethers.Contract(address!, abi, info.provider);
    } catch {
        //console.error("Can't get contract", info);
        return null;
    }
};

export const getContractExchange = async (info: ContractInstance) => {
    try {
        if (!info.network || !info.token) return null;

        const abi = (await EXCHANGE_TOKENS[info.token]!.abi![info.network]![info.contractName]!()).default;
        const address = getAddressExchange(info);
        return new ethers.Contract(address!, abi, info.provider);
    } catch {
        // console.error("Can't get contract", info);
        return null;
    }
};

export const getContractGame = async (info: ContractInstance) => {
    try {
        if (!info.network) return null;

        const abi = (await GAME_CONFIG.abi![info.network]![info.contractName]!()).default;
        const address = getAddressGame(info);
        return new ethers.Contract(address!, abi, info.provider);
    } catch {
        console.error("Can't get contract", info);
        return null;
    }
};

export const getAddress = (info: ContractInstance) =>
    !info.network ? null : TOKEN_CONFIG[info.token]!.address[info.network]![info.contractName]!;

export const getAddressExchange = (info: ContractInstance) =>
    !info.network ? null : EXCHANGE_TOKENS[info.token]!.address![info.network]![info.contractName]!;

export const getAddressGame = (info: ContractInstance) =>
    !info.network ? null : GAME_CONFIG.address![info.network]![info.contractName]!;

export const getLogs = async (eventName: any, params: any, contract: any) => {
    if (!contract) return null;
    let eventFilter = contract.filters[eventName](...params);
    let events = await contract.queryFilter(eventFilter);
    const logs = events.map((item: any) => item.args);
    return logs;
};
