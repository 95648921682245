import { LocalStorageKeys } from '@/constants/local-storage';
import { useAlerts } from '@/hooks/alerts';
import { useEvents } from '@/hooks/events';
import { useWallet } from '@/hooks/wallet';
import { appEventsAtom, appTransactionsAtom, Events } from '@/state/app';
import { walletAtom } from '@/state/wallet';
import { useEffect, useState } from 'react';
import { useLocalStorage } from 'react-use';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useAptosWalletFunctions } from './aptos/aptos';

export interface Transaction {
    uniqueId?: string;
    hash: string;
    chainId: number;
    address: string;
    successMsg?: string;
    failedMsg?: string;
    event: Events;
}

export const useTransactionsManager = () => {
    const { pushEvent } = useEvents();
    const { provider } = useWallet();
    const { getMartianProvider } = useAptosWalletFunctions();
    const { alertError, alertSuccess } = useAlerts();
    const appEvents = useRecoilValue(appEventsAtom);
    const walletState = useRecoilValue(walletAtom);
    const [transactions, setTransactions] = useRecoilState(appTransactionsAtom);
    const [value, setValue] = useLocalStorage(LocalStorageKeys.TRANSACTIONS, undefined, {
        raw: false,
        serializer: (value: any) => JSON.stringify(value),
        deserializer: (value: any) => JSON.parse(value),
    });
    const [polling, setPolling] = useState<NodeJS.Timer | null>();

    // Initial load from disk
    useEffect(() => {
        if (value && value.length > 0) setTransactions(value);
    }, []);

    // Set-up polling for transactions
    useEffect(() => {
        if (transactions.length > 0 && !polling) {
            const id = setInterval(function () {
                pushEvent(Events.transactionEvent, Date.now());
            }, 3000);
            setPolling(id);
        } else if (transactions.length == 0 && polling) {
            clearInterval(polling);
            setPolling(null);
        }
    }, [transactions]);

    // Checks transactions
    useEffect(() => {
        if (transactions.length > 0) checkTransactions().then();
    }, [appEvents.transactionEvent]);

    const getNewPendingHashes = async (pending: Transaction[]) => {
        const newPendingHashes: string[] = [];
        for (const tx of pending) {
            const isRightNetworkAndWallet =
                tx.chainId === walletState.chainId &&
                tx.address.toLocaleLowerCase() === walletState.selectedAddress?.toLocaleLowerCase();
            if (!isRightNetworkAndWallet) continue;

            if (walletState.walletType === 'aptos') {
                const aptosData = await getMartianProvider().getTransaction(tx.hash);
                if (aptosData.success) {
                    const msg = tx.successMsg ?? 'Transaction successful';
                    alertSuccess(msg, tx.hash);
                    pushEvent(tx.event);
                } else if (aptosData.type === 'pending_transaction') {
                    newPendingHashes.push(tx.hash);
                } else {
                    // Error handling
                    const msg = tx.failedMsg ?? 'Transaction failed';
                    alertError(msg, tx.hash);
                }
            } else {
                let receipt = await provider!.getTransaction(tx.hash);
                if (!receipt || receipt.confirmations > 0) {
                    if (receipt && receipt!.confirmations > 0) {
                        const r = await provider!.getTransactionReceipt(tx.hash);
                        if (r.status == 0) {
                            const msg = tx.failedMsg ?? 'Transaction failed';
                            alertError(msg, tx.hash);
                        } else {
                            const msg = tx.successMsg ?? 'Transaction successful';
                            alertSuccess(msg, tx.hash);
                            pushEvent(tx.event);
                        }
                    } else {
                        const msg = tx.failedMsg ?? 'Transaction failed';
                        alertError(msg, tx.hash);
                    }
                    continue;
                }
            }
            newPendingHashes.push(tx.hash);
        }
        return newPendingHashes;
    };

    const checkTransactions = async () => {
        if (provider || getMartianProvider()) {
            const hashes = await getNewPendingHashes(transactions);
            const newPending = transactions.filter((x) => hashes.includes(x.hash));
            setTransactions(newPending);
            setValue(newPending);
        }
    };

    return {};
};

export const useTransactions = () => {
    const { provider } = useWallet();
    const { pushEvent } = useEvents();
    const [transactions, setTransactions] = useRecoilState(appTransactionsAtom);
    const [value, setValue] = useLocalStorage(LocalStorageKeys.TRANSACTIONS, undefined, {
        raw: false,
        serializer: (value: any) => JSON.stringify(value),
        deserializer: (value: any) => JSON.parse(value),
    });

    const waitTx = async (hash: string) => {
        await provider?.waitForTransaction(hash);
        pushEvent(Events.transactionEvent);
    };

    const pushTx = (transaction: Transaction) => {
        if (transaction.hash) {
            const newList = [...transactions, transaction];
            setTransactions(newList);
            setValue(newList);
            waitTx(transaction.hash).then();
        }
    };

    return { pushTx };
};
