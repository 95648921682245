import { EXTERNAL_URLS } from '@/constants/urls';
import { useMixpanel } from '@/hooks/mixpanel/mixpanel';
import { useHelpCenterItems, useHelpCenterState } from '@/hooks/support/help-center';
import { useTranslation } from '@/hooks/support/translate';
import { isNull, Maybe } from '@/utils/common';
import { AnimatePresence, motion } from 'framer-motion';
import { Cancel, QuestionMark, Search } from 'iconoir-react';
import { ChangeEventHandler, FC, useEffect, useState } from 'react';
import { Button } from '../atom/Button';
import { HelpCenterItem } from '../molecule/HelpCenterItem';

interface HelpCenterProps {}
export const HelpCenter: FC<HelpCenterProps> = (props) => {
    const { t } = useTranslation();
    const {
        helpCenterState: { isOpen },
        openCenter,
        closeCenter,
    } = useHelpCenterState();
    const { track } = useMixpanel();
    const [openItemId, setOpenItemId] = useState<Maybe<string>>(null);
    const [searchFilter, setSearchFilter] = useState<Maybe<string>>(null);
    const { list, hasNoResults } = useHelpCenterItems(searchFilter);

    const handleSearchChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        const value = e.target.value.length > 0 ? e.target.value : null;
        setSearchFilter(value);
    };

    const handleHelpCenterClick = () => {
        track('HELP-CENTER-OPEN');
        openCenter();
    };

    useEffect(() => {
        const HELP_CENTER_PREFIX = '#help-center:';
        const hash = window.location.hash;
        const isShallowUrl = hash.startsWith(HELP_CENTER_PREFIX);
        if (!isShallowUrl) return;
        openCenter();
        const id = hash.replace(HELP_CENTER_PREFIX, '');
        setOpenItemId(id);
    }, []);

    return (
        <>
            <QuestionMark
                className="fixed p-2 text-white rounded-full shadow cursor-pointer z-modal right-4 bottom-4 lg:bottom-12 lg:right-12 bg-main-50 hover:bg-main-40"
                onClick={handleHelpCenterClick}
                id="help-center-toggle-btn"
                fr="true"
                height={'42px'}
                width={'42px'}
            />
            <AnimatePresence>
                {isOpen && (
                    <motion.div className="fixed w-full md:w-96 md:right-0 xl:relative z-[999] flex flex-col h-screen bg-grey-60 flex-shrink-0 shadow-lg">
                        <div className="flex items-center gap-2 px-4 pt-4 mb-4">
                            <Button name="Close" variant="flat" size="icon" onClick={closeCenter}>
                                <Cancel fr="true" />
                            </Button>
                            <div className="t__subtitle--s1">{t('helpCenter.title')}</div>
                            <a
                                href={EXTERNAL_URLS.helpCenterContact}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="px-4 py-2 ml-auto rounded-full t__body--b3 text-main-10 bg-main-50 ring-1 ring-main-70 hover:bg-opacity-80"
                                id="ask-question-cta"
                            >
                                Ask a question?
                            </a>
                        </div>
                        <div className="relative mx-4 mb-4">
                            <input
                                onChange={handleSearchChange}
                                type="text"
                                placeholder="Search"
                                className="w-full py-3 pl-12 pr-3 rounded-lg outline-none bg-grey-50 peer ring-1 ring-grey-60 focus:ring-main-50 t__body--b2 "
                            />
                            <Search className="absolute top-3 left-3 text-grey-40 peer-focus:text-main-50" fr="true" />
                        </div>
                        <div className="px-4 t__body--b3 text-grey-30">
                            {isNull(searchFilter) ? t('helpCenter.caption.default') : t('helpCenter.caption.search')}
                        </div>
                        <div className="flex flex-col h-auto gap-4 pt-2 pb-4 pl-4 pr-2 mr-2 overflow-y-scroll div">
                            {list.map((item, index) => (
                                <HelpCenterItem key={index} {...item} isOpen={item.id === openItemId} />
                            ))}
                        </div>
                        {hasNoResults && (
                            <div className="px-4 t__body--b2 text-grey-30">{t('helpCenter.resultsNotFound')}</div>
                        )}
                    </motion.div>
                )}
            </AnimatePresence>
        </>
    );
};
