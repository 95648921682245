import { useRecoilValue } from 'recoil';

import { Button } from '@/components/atom/Button';
import { MAINNET } from '@/constants/env';
import { useMixpanel } from '@/hooks/mixpanel/mixpanel';
import { useTranslation } from '@/hooks/support/translate';
import { appConfigAtom, userTransactionsAtom } from '@/state/app';
import { getNetworkByToken } from '@/utils/utils';
import { useRouter } from 'next/router';
import { FC } from 'react';
import { Loader } from './atom/Loader';
import { NetworkIndicator } from './molecule/NetworkIndicator';
import { AptosWalletInfoBox } from './wallet/AptosWalletInfoBox';
import { WalletInfoBox } from './wallet/WalletInfoBox';

export const AppNav: FC = () => {
    const userTransactions = useRecoilValue(userTransactionsAtom);
    const appConfig = useRecoilValue(appConfigAtom);
    const { t } = useTranslation();
    const { track } = useMixpanel();
    const router = useRouter();
    const isAptosPage = router.asPath.includes('/aptos');

    return (
        <div className="flex items-center justify-center gap-4 ml-auto">
            <NetworkIndicator
                network={
                    MAINNET ? getNetworkByToken(appConfig.token).family.name : getNetworkByToken(appConfig.token).name
                }
                onClick={() => {
                    track('NAV-BUTTON 1');
                }}
            />
            {userTransactions.length > 0 ? (
                <Button suffix={<Loader color="#fff" />} className="pointer-events-none">
                    <span>{userTransactions.length} Pending</span>
                </Button>
            ) : isAptosPage ? (
                <AptosWalletInfoBox />
            ) : (
                <WalletInfoBox className="h-10" />
            )}
        </div>
    );
};
