import { TOKEN_CONFIG } from '@/constants/tokens';
import { appConfigAtom, appEventsAtom, aptosDataAtom, Events } from '@/state/app';
import { walletAtom, walletIsValidNetwork } from '@/state/wallet';
import { aptosToEth } from '@/utils/common';
import { BigNumber } from 'ethers';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { match } from 'ts-pattern';
import { WithdrawOrder } from '@/constants/types';

export interface AptosConnect {
    address: string;
    method: string;
    publicKey: string;
    status: number;
}

export interface AptosAccount {
    address: string;
    publicKey: string;
}

export type AptosNetwork = 'Devnet' | 'Testnet' | 'Custom';

export const useAptosWalletFunctions = () => {
    const router = useRouter();
    const isAptosPage = router.asPath.includes('/aptos');
    const [aptosProvider, setAptosProvider] = useState();
    const [walletState, setWalletState] = useRecoilState(walletAtom);

    useEffect(() => {
        if (walletState.walletType !== 'aptos') {
            return;
        }
        setAptosProvider(getMartianProvider());
        detectNetworkChange();
        detectAccountChange();
    }, [walletState.walletType]);

    const getMartianProvider = () => {
        //@ts-expect-error
        return window ? window?.martian : undefined;
    };

    const getAptosAccount = async (): Promise<AptosAccount | null> => {
        return await getMartianProvider()?.account();
    };

    const getIsAptosAccountConnected = async (): Promise<boolean> => {
        return (await getMartianProvider()?.isConnected()) ?? false;
    };

    const connectAptosWallet = async (): Promise<AptosConnect | null> => {
        return await getMartianProvider()?.connect();
    };

    const disconnectAptosWallet = async () => {
        await getMartianProvider()?.disconnect();
    };

    const getAptosAccountResources = async (address: string) => {
        await getMartianProvider()?.getAccountResources(address);
    };

    const getAptosNetwork = async (): Promise<AptosNetwork> => {
        const network = await getMartianProvider()?.network();
        return network;
    };

    const getAptosChainId = async (): Promise<number> => {
        const network = await getMartianProvider()?.getChainId();
        return network.chainId;
    };

    const detectNetworkChange = () => {
        if (isAptosPage) {
            getMartianProvider()?.onNetworkChange((name: string) => {
                if (name === 'Testnet') {
                    setWalletState((previous) => ({
                        ...previous,
                        walletType: 'aptos',
                        chainId: 2,
                    }));
                } else {
                    setWalletState((previous) => ({
                        ...previous,
                        walletType: 'aptos',
                        chainId: 0,
                    }));
                }
            });
        }
    };

    const detectAccountChange = () => {
        if (isAptosPage) {
            getMartianProvider()?.onAccountChange((address: string) => {
                setWalletState((previous) => ({
                    ...previous,
                    selectedAddress: address,
                }));
            });
        }
    };

    return {
        aptosProvider,
        getMartianProvider,
        getAptosAccount,
        getAptosAccountResources,
        getIsAptosAccountConnected,
        getAptosNetwork,
        getAptosChainId,
        connectAptosWallet,
        disconnectAptosWallet,
    };
};

export const getAptosContract = () => {
    const tokenConfig = TOKEN_CONFIG.APTOS;
    const contractAddress = tokenConfig?.address.aptos_testnet?.ClayMain;
    // @ts-expect-error Martian provider does not define type
    const provider = window.martian;

    const generateTx = async (sender: string, payload: any) => {
        const transaction = await provider?.generateTransaction(sender, payload);
        return transaction;
    };

    const signTx = async (transaction: any) => {
        const signedTxn = await provider?.signTransaction(transaction);
        return signedTxn;
    };

    const submitTxn = async (signedTxn: any) => {
        const txnHash = await provider?.submitTransaction(signedTxn);
        return txnHash;
    };

    const doTxn = async (address: string, payload: any) => {
        const txn = await generateTx(address, payload).catch(console.warn);
        const txnSignature = await signTx(txn);
        const txHash = submitTxn(txnSignature);
        return txHash;
    };

    const deposit = async (address: string, amount: number): Promise<string> => {
        const payload = {
            function: `${contractAddress}::clay_aptos::deposit`,
            type_arguments: [],
            arguments: [amount],
        };
        const txHash = await doTxn(address, payload);
        return txHash.hash;
    };

    const withdraw = async (address: string, amount: number): Promise<string> => {
        const payload = {
            function: `${contractAddress}::clay_aptos::withdraw`,
            type_arguments: [],
            arguments: [amount],
        };
        const txHash = await doTxn(address, payload);
        return txHash.hash;
    };

    const instantWithdraw = async (address: string, amount: number): Promise<string> => {
        const payload = {
            function: `${contractAddress}::clay_aptos::instant_withdraw`,
            type_arguments: [],
            arguments: [amount],
        };
        const txHash = await doTxn(address, payload);
        return txHash.hash;
    };

    const claim = async (address: string, orderId: number): Promise<any> => {
        const payload = {
            function: `${contractAddress}::clay_aptos::claim`,
            type_arguments: [],
            arguments: [orderId],
        };
        const txHash = await doTxn(address, payload);
        return txHash;
    };

    return {
        deposit,
        withdraw,
        instantWithdraw,
        claim,
    };
};

export interface AccountResource<T = string> {
    type: string;
    data: Data;
}

export interface Data {
    packages?: Package[];
    decimals?: number;
    name?: string;
    supply?: Supply;
    symbol?: string;
    coin?: Coin;
    deposit_events?: ClaimEvent;
    frozen?: boolean;
    withdraw_events?: ClaimEvent;
    authentication_key?: string;
    coin_register_events?: ClaimEvent;
    guid_creation_num?: string;
    key_rotation_events?: ClaimEvent;
    rotation_capability_offer?: CapabilityOffer;
    sequence_number?: string;
    signer_capability_offer?: CapabilityOffer;
    exchange_rate?: string;
    pending_deposits?: string;
    pending_withdraws?: string;
    liquidity?: string;
    burn_cap?: Cap;
    freeze_cap?: Cap;
    mint_cap?: Cap;
    claim_event?: ClaimEvent;
    deposit_event?: ClaimEvent;
    instant_withdraw_event?: ClaimEvent;
    withdraw_event?: ClaimEvent;
    last_order_id?: string;
    orders?: Orders;
    apt_staked?: string;
    cs_apt_staked?: string;
    signer_cap?: SignerCap;
}

export interface Cap {
    dummy_field: boolean;
}

export interface ClaimEvent {
    counter: string;
    guid: GUID;
}

export interface GUID {
    id: ID;
}

export interface ID {
    addr: string;
    creation_num: string;
}

export interface Coin {
    value: string;
}

export interface Orders {
    data: Datum[];
}

export interface Datum {
    key: string;
    value: Value;
}

export interface Value {
    amount: string;
    is_claimed: boolean;
    order_id: string;
    timestamp: string;
}

export interface Package {
    deps: Dep[];
    extension: Supply;
    manifest: string;
    modules: Module[];
    name: string;
    source_digest: string;
    upgrade_number: string;
    upgrade_policy: UpgradePolicy;
}

export interface Dep {
    account: string;
    package_name: string;
}

export interface Vec {
    aggregator?: Supply;
    integer?: Supply;
    limit?: string;
    value?: string;
}

export interface Supply {
    vec: Vec[];
}

export interface Module {
    extension: Supply;
    name: string;
    source: string;
    source_map: string;
}

export interface UpgradePolicy {
    policy: number;
}

export interface CapabilityOffer {
    for: Supply;
}

export interface SignerCap {
    account: string;
}

export interface AptosWithdrawOrder {
    order_id: number;
    amount: number;
    timestamp: number;
    is_claimed: boolean;
}

const parseResource = (resource: AccountResource, contractAddress: string) =>
    match(resource.type)
        .when(
            (type) => type.endsWith('<0x1::aptos_coin::AptosCoin>'),
            () => {
                return {
                    aptosBalance: aptosToEth(+(resource.data.coin?.value ?? 0)),
                };
            },
        )
        .when(
            (type) => type.endsWith(`<${contractAddress}::clay_aptos::CsAptos>`),
            () => {
                return {
                    csAptosBalance: aptosToEth(+(resource.data.coin?.value ?? 0)),
                };
            },
        )
        .when(
            (type) => type.endsWith('::clay_aptos::Funds'),
            () => ({
                exchangeRate: aptosToEth(+(resource.data.exchange_rate ?? 0)).div(100000) ?? BigNumber.from(0),
                pendingDeposits: +(resource.data.pending_deposits ?? 0),
                pendingWithdraws: +(resource.data.pending_withdraws ?? 0),
                instantWithdrawLiquidity: +(resource.data.liquidity ?? 0),
            }),
        )
        .when(
            (type) => type.endsWith('::clay_aptos::CsAptCaps'),
            () => ({
                burnCap: resource.data.burn_cap?.dummy_field ?? false,
                freezeCap: resource.data.freeze_cap?.dummy_field ?? false,
                mintCap: resource.data.mint_cap?.dummy_field ?? false,
            }),
        )
        .when(
            (type) => type.endsWith('::clay_aptos::UserBalance'),
            () => ({
                stakedAmount: +(resource.data.apt_staked ?? 0),
            }),
        )
        .when(
            (type) => type.endsWith('::clay_aptos::UserOrders'),
            () => ({
                withdrawOrders:
                    resource.data.orders?.data?.map((item) => item.value as unknown as AptosWithdrawOrder) ?? [],
            }),
        )
        .otherwise(() => null);

// Generated by https://quicktype.io

export interface AptosGetters {
    burnCap: boolean;
    freezeCap: boolean;
    mintCap: boolean;
    exchangeRate: BigNumber;
    pendingDeposits: number;
    pendingWithdraws: number;
    csAptosBalance: BigNumber;
    aptosBalance: BigNumber;
    instantWithdrawLiquidity: number;
    processedWithdrawOrders: WithdrawOrder[];
    withdrawOrders: AptosWithdrawOrder[];
    claimableAmount: BigNumber;
}

const APTOS_UNBONDING_TIME = 262980000;

export const useAptosGetters = () => {
    const tokenConfig = TOKEN_CONFIG.APTOS;
    const contractAddress = tokenConfig?.address.aptos_testnet?.ClayMain;
    const walletState = useRecoilValue(walletAtom);
    const appEvents = useRecoilValue(appEventsAtom);
    const appConfig = useRecoilValue(appConfigAtom);
    const isWalletValidNetwork = useRecoilValue(walletIsValidNetwork);
    const setState = useSetRecoilState<Partial<AptosGetters>>(aptosDataAtom);

    const getContractResources = async (): Promise<AccountResource[]> =>
        // @ts-expect-error
        await window.martian?.getAccountResources(contractAddress);

    const getUserResources = async (address: string): Promise<AccountResource[]> =>
        // @ts-expect-error
        await window.martian?.getAccountResources(address);

    const getProcessedWithdrawOrders = (orders: AptosWithdrawOrder[] | undefined) => {
        if (isWalletValidNetwork && walletState.selectedAddress && appConfig.token === 'APTOS') {
            const APTOS_UNBONDING_TIME = 262980000;
            const withdrawOrders =
                orders?.map((order) => {
                    return {
                        isClaimed: order.is_claimed,
                        isClaimable: order.timestamp * 1000 + APTOS_UNBONDING_TIME < Date.now() && !order.is_claimed,
                        isEarlyClaimable: false,
                        txHash: '',
                        claimTime: order.timestamp * 1000 + APTOS_UNBONDING_TIME,
                        currentTime: Date.now(),
                        epochsLeft: 0,
                        amount: aptosToEth(order.amount).mul(10),
                        orderId: order.order_id,
                        fee: BigNumber.from(0),
                    } as WithdrawOrder;
                }) ?? [];
            return withdrawOrders;
        } else {
            return [] as WithdrawOrder[];
        }
    };

    const calculateTotalClaims = (orders: AptosWithdrawOrder[] | undefined) => {
        const availableOrders = orders?.filter((order) => {
            return order.timestamp * 1000 + APTOS_UNBONDING_TIME < Date.now() && !order.is_claimed;
        });

        const totalClaims = availableOrders?.reduce((accumulator, order) => {
            return accumulator + +order.amount;
        }, 0);

        return aptosToEth(totalClaims).mul(10) ?? BigNumber.from(0);
    };

    const refreshState = async (userAddress: string) => {
        const userResources = await getUserResources(userAddress);
        const contractResources = (await getContractResources()).filter(
            (resource) => !resource.type.includes('UserOrders'),
        );
        const allResources = [...userResources, ...contractResources];
        const parsed = allResources.reduce(
            (acc, r) => ({ ...parseResource(r, contractAddress!), ...acc }),
            {} as Partial<AptosGetters>,
        );

        setState((previous) => ({
            ...previous,
            ...parsed,
            claimableAmount: calculateTotalClaims(parsed?.withdrawOrders),
            processedWithdrawOrders: getProcessedWithdrawOrders(parsed?.withdrawOrders),
        }));
        return parsed;
    };

    useEffect(() => {
        if (walletState.walletType !== 'aptos' || !walletState.selectedAddress) return;
        refreshState(walletState.selectedAddress);
    }, [
        walletState.walletType,
        walletState.selectedAddress,
        appEvents.updateValuesEvent,
        appEvents.stakeEvent,
        appEvents.unstakeEvent,
        appEvents.flashExitEvent,
        appEvents.claimEvent,
    ]);

    return {
        refreshState,
    };
};

export interface AptosValidationInputs {
    event: Events;
    csAptosBalance: BigNumber | undefined;
    aptosBalance: BigNumber | undefined;
    instantWithdrawLimit: number | undefined;
    unitInput: BigNumber | undefined;
}

export interface AptosValidationResult {
    errorText: string;
    isValid: boolean;
}

export const doAptosValidations = (inputs: AptosValidationInputs) => {
    let isFlashExit = inputs.event === Events.flashExitEvent;
    let isStaking = inputs.event === Events.stakeEvent;
    let isValid = true;
    let errorText = '';

    if (!inputs.unitInput || !inputs.csAptosBalance || !inputs.aptosBalance || !inputs.instantWithdrawLimit) {
        isValid = false;
        return {
            errorText,
            isValid,
        };
    }
    if (inputs.unitInput.lte(BigNumber.from(0))) {
        isValid = false;
        errorText = '';
    } else if (isFlashExit) {
        if (inputs.unitInput.gt(aptosToEth(inputs.instantWithdrawLimit))) {
            isValid = false;
            errorText = 'Amount exceeds limit';
        } else if (inputs.csAptosBalance.lt(inputs.unitInput)) {
            isValid = false;
            errorText = 'Insufficient balance';
        }
    } else if (!isStaking) {
        if (inputs.csAptosBalance.div(10).lt(inputs.unitInput)) {
            isValid = false;
            errorText = 'Insufficient balance';
        }
    } else {
        if (inputs.aptosBalance.lt(inputs.unitInput)) {
            isValid = false;
            errorText = 'Insufficient balance';
        }
    }
    return {
        errorText,
        isValid,
    };
};
