import { useRouter } from 'next/router';
import { CSSProperties, useEffect, useState } from 'react';
import { useEvent } from 'react-use';

const px = (n: number) => `${n}px`;

const DEFAULT_STYLES = {
    left: '45%',
    top: '120px',
    width: '50vw',
    height: '50vh',
};
export const useAnchorBackground = () => {
    const { route } = useRouter();

    const [style, setStyle] = useState<CSSProperties>(DEFAULT_STYLES);

    const updateBackground = () => {
        const anchor = document.querySelector('[data-bg-anchor]');
        if (!anchor) {
            setStyle(DEFAULT_STYLES);
            return;
        }
        const coords = anchor.getBoundingClientRect();
        const newStyles: CSSProperties = {
            top: px(coords.top),
            left: px(coords.left),
            width: px(coords.width),
            height: px(coords.height),
        };
        setStyle(newStyles);
    };
    const hideBackground = () => {
        setStyle({
            display: 'none',
        });
    };
    const initBackground = () => {
        if (route === '/') {
            return hideBackground();
        } else {
            return updateBackground();
        }
    };

    useEffect(() => {
        return initBackground();
    }, [route]);
    useEvent('resize', initBackground);

    return { style };
};
