import { useLanguage } from '@/hooks/support/language';
import classNames from 'classnames';
import { Circle } from 'iconoir-react';

type LanguageSelectorProps = {
    isLanguageMenuOpen: boolean;
    className?: string;
    onLangSelected: () => void;
};

export const LanguageSelector = ({ isLanguageMenuOpen, className, onLangSelected }: LanguageSelectorProps) => {
    const { localesList, currentLocale, handleLanguageChange } = useLanguage();

    const onClick = (locale: string) => {
        handleLanguageChange(locale);
        onLangSelected();
    };

    return (
        <>
            {isLanguageMenuOpen
                ? localesList.map((item, index) => (
                      <div key={index}>
                          <a
                              className={classNames(
                                  'mobile-nav__item text-white hover:bg-[#4d3964] flex items-center gap-2',
                                  {
                                      'pointer-events-none': item.locale === currentLocale,
                                  },
                                  className,
                              )}
                              role="button"
                              onClick={() => onClick(item.locale)}
                              id={`lang-${item.locale}-option`}
                          >
                              <span>{item.label}</span>
                              {currentLocale === item.locale ? (
                                  <Circle className="w-3 h-3 ml-auto fill-current text-green-60" fr="true" />
                              ) : null}
                          </a>
                      </div>
                  ))
                : null}
        </>
    );
};
