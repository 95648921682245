import { Button } from '@/components/atom/Button';
import { FAQItem } from '@/hooks/support/help-center';
import markdownStyles from '@/styles/Markdown.module.css';
import classNames from 'classnames';
import { Cancel } from 'iconoir-react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useRef, useState } from 'react';

type HelpCenterItemProps = FAQItem & {
    isOpen: boolean;
};
const MAX_CHARACTER_COUNT = 180;

export const HelpCenterItem = ({
    id,
    title,
    isOpen: _isOpen = false,
    description,
    html,
    isEmbed,
    youtubeId,
}: HelpCenterItemProps) => {
    const parser = new DOMParser();
    const router = useRouter();
    const itemEl = useRef<HTMLDivElement>(null);
    const [isOpen, setOpen] = useState(_isOpen);
    const handleCardClick = () => {
        !isOpen && setOpen(true);
        router.push(`#help-center:${id}`, undefined, { shallow: true });
    };
    const handleCloseClick = () => setOpen(false);

    const isReadMoreVisible = useMemo(
        () => parser.parseFromString(html, 'text/html').body.textContent!.length > MAX_CHARACTER_COUNT,
        [html],
    );

    useEffect(() => {
        if (!isOpen) return;
        itemEl.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
        });
    }, [isOpen]);

    return (
        <div
            className={classNames('relative ring-1 ring-grey-50 bg-grey-50 rounded-lg shadow-sm shadow-grey-40 p-4', {
                'ring-main-40': isOpen,
                'cursor-pointer': !isOpen,
            })}
            onClick={handleCardClick}
            ref={itemEl}
        >
            {isOpen && (
                <div className="absolute right-1 top-1">
                    <Button name="Close" variant="flat" size="icon" onClick={handleCloseClick}>
                        <Cancel className="text-xs" fr="true" />
                    </Button>
                </div>
            )}
            <div className="pr-6 mb-2 text-white t__subtitle--s2">{title}</div>
            {!isEmbed && (
                <div className="flex flex-col t__body--b3 text-grey-30">
                    <div
                        className={classNames(markdownStyles.markdown, {
                            faq__content: !isOpen,
                        })}
                        dangerouslySetInnerHTML={{ __html: html }}
                    ></div>
                    {isReadMoreVisible && !isOpen && <span className="text-grey-30">Read more</span>}
                </div>
            )}
            {isEmbed && (
                <div className={classNames({ 'grid grid-cols-2 gap-2': !isOpen })}>
                    {!isOpen && (
                        <div
                            className={classNames('t__body--b3 mb-2', markdownStyles.markdown, {
                                faq__content: !isOpen,
                            })}
                        >
                            {description}
                        </div>
                    )}
                    {!isOpen && (
                        <div>
                            <Image
                                width={180}
                                height={120}
                                className="rounded-md"
                                alt="Youtube thumbnail"
                                src={`https://i3.ytimg.com/vi/${youtubeId}/sddefault.jpg`}
                            />
                        </div>
                    )}
                    {isOpen && <div className="mb-2 t__body--b3">{description}</div>}
                    {isOpen && (
                        <div className={markdownStyles.markdown} dangerouslySetInnerHTML={{ __html: html }}></div>
                    )}
                </div>
            )}
        </div>
    );
};
HelpCenterItem.defaultProps = {
    isOpen: false,
};
