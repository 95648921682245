import { Network, NetworkConfig, NetworkFamily, NetworkFamilyConfig, Token, TokenConfig, TokenVocab } from './types';

const TokenAbi = () => import('@/utils/contracts/abi/Token.abi.json');
const CsToken = () => import('@/utils/contracts/abi/CsToken.abi.json');
const ClayMatic = () => import('@/utils/contracts/abi/ClayMatic.abi.json');
const ClayExchangeV1 = () => import('@/utils/contracts/abi/ClayExchangeV1.abi.json');
const ClayManager = () => import('@/utils/contracts/abi/ClayManager.abi.json');
const ClayETH = () => import('@/utils/contracts/abi/ClayETH.abi.json');

const TOKEN_STYLES: Partial<Record<Token, TokenVocab['styles']>> = {
    CSMATIC: {
        backgroundColor: 'bg-[#24173C]',
        backgroundImage: '/images/matic--bg-card.png',
        buttonColor: 'bg-[#2b235c]',
    },
    APTOS: {
        backgroundColor: 'bg-[#24173C]',
        backgroundImage: '/images/aptos--bg-card.png',
        buttonColor: 'bg-[#03335f]',
    },
    CSETH: {
        backgroundColor: 'bg-[#24173C]',
        backgroundImage: '/images/eth--bg-card.png',
        buttonColor: 'bg-[#03335f]',
    },
};
const NETWORK_FAMILY_CONFIG: Record<NetworkFamily, NetworkFamilyConfig> = {
    ethereum: {
        name: 'Ethereum',
        icon: {
            asset: 'ethereum',
            height: 24,
            width: 16,
        },
    },
    polygon: {
        name: 'Polygon',
        icon: {
            asset: 'matic',
            height: 24,
            width: 24,
        },
    },
    aptos: {
        name: 'Aptos',
        icon: {
            asset: 'aptos',
            height: 24,
            width: 24,
        },
    },
    avalanche: {
        name: 'Avalanche',
        icon: {
            asset: 'avax',
            height: 24,
            width: 24,
        },
    },
};
export const NETWORK_CONFIG: Record<Network, NetworkConfig> = {
    goerli: {
        _id: 'goerli',
        explorer: 'https://goerli.etherscan.io/tx/',
        chainId: 5,
        name: 'Goerli',
        family: NETWORK_FAMILY_CONFIG.ethereum,
        currency: 'ETH',
    },
    localhost: {
        _id: 'localhost',
        explorer: 'https://goerli.etherscan.io/tx/',
        chainId: 1337,
        name: 'Local',
        family: NETWORK_FAMILY_CONFIG.ethereum,
        currency: 'ETH',
    },
    mainnet: {
        _id: 'mainnet',
        explorer: 'https://etherscan.io/tx/',
        chainId: 1,
        name: 'Mainnet',
        family: NETWORK_FAMILY_CONFIG.ethereum,
        currency: 'ETH',
    },
    mumbai: {
        _id: 'mumbai',
        explorer: 'https://mumbai.polygonscan.com/tx/',
        chainId: 80001,
        name: 'Mumbai',
        family: NETWORK_FAMILY_CONFIG.polygon,
        currency: 'MATIC',
    },
    polygon: {
        _id: 'polygon',
        explorer: 'https://polygonscan.com/tx/',
        chainId: 137,
        name: 'Polygon',
        family: NETWORK_FAMILY_CONFIG.polygon,
        currency: 'MATIC',
    },
    aptos_testnet: {
        _id: 'aptos_testnet',
        explorer: 'https://explorer.aptoslabs.com/txn/',
        chainId: 2,
        name: 'Aptos testnet',
        family: NETWORK_FAMILY_CONFIG.aptos,
        currency: 'APTOS',
    },
    avalanche: {
        _id: 'avalanche',
        explorer: 'https://subnets.avax.network/c-chain',
        chainId: 43114,
        name: 'Avalanche C-Chain',
        family: NETWORK_FAMILY_CONFIG.avalanche,
        currency: 'AVAX',
    },
    fuji: {
        _id: 'fuji',
        explorer: 'https://subnets.avax.network/c-chain',
        chainId: 43113,
        name: 'Avalanche Fuji Testnet',
        family: NETWORK_FAMILY_CONFIG.avalanche,
        currency: 'AVAX',
    },
};
export const TOKEN_CONFIG: Partial<Record<Token, TokenConfig>> = {
    CSETH: {
        testnet: NETWORK_CONFIG.goerli,
        mainnet: NETWORK_CONFIG.mainnet,
        vocab: {
            _id: 'CSETH',
            slug: 'ethereum',
            name: 'Ethereum',
            symbol: 'ETH',
            csSymbol: 'csETH',
            inTestNet: false, // TODO disable this / adjust testnet/alpha version
            inMainnet: false,
            isChild: false,
            isFlashExitEnabled: true,
            parentId: 'CSETH',
            isNative: true,
            isChartEnabled: false,
            styles: TOKEN_STYLES.CSETH,
            apr: 0.05,
        },
        cgId: 'ethereum',
        assets: {
            ETH: 'eth',
            csETH: 'cs-eth',
        },
        checkpointTime: {},
        address: {
            goerli: {
                CsToken: '0x20581920070F65B6dA60FFc465b2180F01fC857B',
                ClayMain: '0x85693DA174CFcd0696d9a8efa1994D12cE78F292',
                ClayExchangeV1: '0x85693DA174CFcd0696d9a8efa1994D12cE78F292',
            },
            mainnet: {
                ClayMain: '',
                ClayExchangeV1: '',
            },
        },
        abi: {
            goerli: {
                Token: TokenAbi,
                CsToken,
                ClayMain: ClayETH,
                ClayExchangeV1,
            },
            mainnet: {
                Token: TokenAbi,
                CsToken,
                ClayMain: ClayETH,
                ClayExchangeV1,
            },
        },
        version: {
            mainnet: 'v1',
            goerli: 'victoria',
        },
    },
    CSMATIC: {
        testnet: NETWORK_CONFIG.goerli,
        mainnet: NETWORK_CONFIG.mainnet,
        vocab: {
            _id: 'CSMATIC',
            slug: 'matic',
            name: 'Polygon',
            symbol: 'MATIC',
            csSymbol: 'csMATIC',
            inTestNet: true,
            inMainnet: true,
            isChild: false,
            isFlashExitEnabled: false,
            parentId: 'CSMATIC',
            isNative: false,
            isChartEnabled: true,
            styles: TOKEN_STYLES.CSMATIC,
            apr: 0.092179,
        },
        cgId: 'matic-network',
        assets: {
            MATIC: 'matic',
            csMATIC: 'cs-matic',
        },
        checkpointTime: {
            goerli: 1800, // 30 minutes https://wallet-dev.polygon.technology/staking/
            mainnet: 1800, // 30 minutes https://staking.polygon.technology/
        },
        address: {
            mainnet: {
                Token: '0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0',
                CsToken: '0x38b7Bf4eeCF3EB530b1529c9401FC37d2a71a912',
                ClayMain: '0x91730940DCE63a7C0501cEDfc31D9C28bcF5F905',
            },
            goerli: {
                Token: '0x499d11e0b6eac7c0593d8fb292dcbbf815fb29ae',
                CsToken: '0x3fb4601911871b635011aF01eDda5854F27560ce',
                ClayMain: '0xE29d3d4d72997b31Ccdf8188113c189f1106f6b8',
            },
            localhost: {
                Token: '0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0',
                CsToken: '0x2910E325cf29dd912E3476B61ef12F49cb931096',
                ClayMain: '0x7bc06c482DEAd17c0e297aFbC32f6e63d3846650',
            },
        },
        abi: {
            mainnet: {
                Token: TokenAbi,
                CsToken,
                ClayMain: ClayMatic,
            },
            goerli: {
                Token: TokenAbi,
                CsToken,
                ClayMain: ClayMatic,
            },
            localhost: {
                Token: TokenAbi,
                CsToken,
                ClayMain: ClayMatic,
            },
        },
        version: {
            mainnet: 'v1',
            goerli: 'victoria',
            localhost: 'victoria',
        },
    },
    CSMATIC_POLYGON: {
        testnet: NETWORK_CONFIG.mumbai,
        mainnet: NETWORK_CONFIG.polygon,
        vocab: {
            _id: 'CSMATIC_POLYGON',
            slug: 'polygon',
            name: 'Polygon',
            symbol: 'MATIC',
            csSymbol: 'csMATIC',
            inTestNet: true,
            inMainnet: true,
            isChild: true,
            isFlashExitEnabled: true,
            parentId: 'CSMATIC',
            isNative: true,
            isChartEnabled: true,
            styles: TOKEN_STYLES.CSMATIC,
            apr: 0.092179,
        },
        cgId: 'matic-network',
        assets: {
            MATIC: 'matic',
            csMATIC: 'cs-matic',
        },
        checkpointTime: {
            goerli: 1500, // 25 minutes https://wallet-dev.polygon.technology/staking/
            mainnet: 900, // 15 minutes https://staking.polygon.technology/
        },
        address: {
            mumbai: {
                CsTokenV1: '0x7c35c1bfd89bbde1c63a45b295a02a07e52e27cf',
                CsToken: '0x2eD77c504bF34D1d38fd9556D3982dF604f9c3f7',
                ClayMain: '0x08dA529F1842EF12D2daeDbE55194899cc22A6bd', // ClayManager
                ClayExchangeV1: '0x89f435FfEECA7bbaEC3C8C7C121261311E4e1012',
            },
            polygon: {
                CsTokenV1: '0x7ed6390f38d554B8518eF30B925b46972E768AF8',
                CsToken: '0xFcBB00dF1d663eeE58123946A30AB2138bF9eb2A',
                ClayMain: '0x318D1E3eF00115C4DF1e0D9af965A8bE8EA1f133', // ClayManager
                ClayExchangeV1: '0x973266BA291f2e1F6c36074041BaCB5c578F8320',
            },
        },
        abi: {
            mumbai: {
                Token: TokenAbi,
                CsToken,
                CsTokenV1: CsToken,
                ClayMain: ClayManager,
                ClayExchangeV1,
            },
            polygon: {
                Token: TokenAbi,
                CsToken,
                CsTokenV1: CsToken,
                ClayMain: ClayManager,
                ClayExchangeV1,
            },
        },
        version: {
            polygon: 'v1',
            mumbai: 'victoria',
        },
    },
    APTOS: {
        testnet: NETWORK_CONFIG['aptos_testnet'],
        mainnet: NETWORK_CONFIG.mainnet,
        vocab: {
            _id: 'APTOS',
            slug: 'aptos',
            name: 'Aptos',
            symbol: 'APT',
            csSymbol: 'csAPT',
            inTestNet: true,
            inMainnet: false,
            isChild: false,
            isFlashExitEnabled: false,
            parentId: 'APTOS',
            isNative: true,
            isChartEnabled: false,
            styles: TOKEN_STYLES.APTOS,
            apr: 0,
        },
        cgId: 'aptos',
        assets: { APT: 'aptos', csAPT: 'aptos' },
        checkpointTime: {},
        address: {
            aptos_testnet: {
                ClayMain: '0xf544ef48cbbbb24bbafeecede5c17e25ae1510b89a6d36d8b461974c8eba87d8',
            },
        },
        abi: {},
        version: {
            mainnet: 'v1',
            aptos_testnet: 'victoria',
        },
    },
};

export const RPC_URLS: Record<Network, string[]> = {
    mainnet: [
        'https://mainnet.infura.io/v3/90f112e4496f4817a561dc440332f492',
        'https://mainnet.infura.io/v3/2ce17bba00d04226a02dc6d69fe9cb99',
        'https://mainnet.infura.io/v3/94a687f398b24310a4f6b48ee9d80869',
    ],
    localhost: ['http://127.0.0.1:8545'],
    goerli: [
        'https://goerli.infura.io/v3/90f112e4496f4817a561dc440332f492',
        'https://goerli.infura.io/v3/2ce17bba00d04226a02dc6d69fe9cb99',
        'https://goerli.infura.io/v3/94a687f398b24310a4f6b48ee9d80869',
        'https://eth-goerli.gateway.pokt.network/v1/lb/61aa25d23914cb00391c4c06',
        'https://eth-goerli.gateway.pokt.network/v1/lb/61aa25ee3914cb00391c4c7f',
    ],
    mumbai: [
        'https://matic-mumbai.chainstacklabs.com',
        'https://rpc-mumbai.maticvigil.com',
        'https://matic-testnet-archive-rpc.bwarelabs.com',
        'https://rpc-mumbai.matic.today', //Moved to last in the list since this is unreliable
    ],
    polygon: [
        'https://rpc-mainnet.matic.network',
        'https://matic-mainnet.chainstacklabs.com',
        'https://rpc-mainnet.maticvigil.com',
        'https://rpc-mainnet.matic.quiknode.pro',
        'https://matic-mainnet-full-rpc.bwarelabs.com',
    ],
    aptos_testnet: ['https://fullnode.testnet.aptoslabs.com/v1'],
    avalanche: [
        'https://api.avax.network/ext/bc/C/rpc',
        'https://rpc.ankr.com/avalanche',
        'https://avalanche-evm.publicnode.com',
        'https://ava-mainnet.public.blastapi.io/ext/bc/C/rpc',
        'https://avalancheapi.terminet.io/ext/bc/C/rpc',
        'https://1rpc.io/avax/c',
    ],
    fuji: [
        'https://rpc.ankr.com/avalanche_fuji',
        'https://api.avax-test.network/ext/bc/C/rpc',
        'https://ava-testnet.public.blastapi.io/ext/bc/C/rpc',
        'https://avalanchetestapi.terminet.io/ext/bc/C/rpc',
        'https://rpc.ankr.com/avalanche_fuji-c',
    ],
};
export const TOKEN_LIST = Object.keys(TOKEN_CONFIG) as Token[];
export const TOKEN_SLUGS = Object.values(TOKEN_CONFIG).map((t) => t.vocab);
