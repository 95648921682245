import { Maybe } from '@/utils/common';
import classNames from 'classnames';
import NextImage from 'next/image';
import { useMemo, useState } from 'react';
import { ensuredForwardRef } from 'react-use';

export type ImageAsset =
    | 'metamask'
    | 'wallet-connect'
    | 'coinbase'
    | 'unstoppable-domains'
    | 'claystack-victoria'
    | 'claystack-victoria-mobile'
    | 'claystack-logo'
    | 'claystack-logo-beta'
    | 'claystack-logo-mobile'
    | 'ethereum'
    | 'matic'
    | 'matic-alt'
    | 'matic-main'
    | 'cs-matic'
    | 'aptos'
    | 'cs-aptos'
    | 'eth'
    | 'eth-alt'
    | 'eth-main'
    | 'cs-eth'
    | 'distribution'
    | 'loader'
    | 'diamond'
    | 'coin'
    | 'fire-emoji'
    | 'savax'
    | 'avax'
    | 'st-matic';

type ImageProps = {
    asset: ImageAsset;
    className?: string;
    width?: number;
    height?: number;
    isIcon?: boolean;
    onClick?: () => void;
};
export const Image = ensuredForwardRef<HTMLDivElement, ImageProps>((props, ref) => {
    const [assetUrl, setAssetUrl] = useState<Maybe<string>>(null);

    const PNG_ICONS = useMemo<ImageAsset[]>(() => ['coinbase', 'fire-emoji'], []);

    useMemo(() => {
        const chunks = [props.isIcon ? 'icon__' : '', props.asset];
        const extension = !props.asset ? null : PNG_ICONS.includes(props.asset) ? 'png' : 'svg';
        const url = `/images/${chunks.join('')}.${extension}`;
        setAssetUrl(url);
    }, [setAssetUrl, props.isIcon, props.asset]);

    return (
        props.asset && (
            <div
                className={classNames('flex items-center', props.className)}
                ref={ref}
                onClick={props.onClick}
                style={{
                    minWidth: props.width,
                    minHeight: props.height,
                }}
            >
                {assetUrl && (
                    <NextImage
                        className={props.className}
                        src={assetUrl}
                        width={props.width}
                        height={props.height}
                        alt={props.asset}
                    />
                )}
            </div>
        )
    );
});
Image.defaultProps = {
    isIcon: false,
    width: 16,
    height: 16,
};
