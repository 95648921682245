import { Button } from '@/components/atom/Button';
import { NavItem, NavItemProps } from '@/components/atom/NavItem';
import { useOnClickOutside } from '@/hooks/mixpanel/detect-click';
import { useMixpanel } from '@/hooks/mixpanel/mixpanel';
import { DefaultProps } from '@/utils/common';
import classNames from 'classnames';
import { MoreVert } from 'iconoir-react';
import { useRef, useState } from 'react';
import { BiArrowBack } from 'react-icons/bi';
import { LanguageSelector } from './LanguageSelector';

export type NavbarMobileSubmenuItem = {
    item: React.ReactNode;
    hiddenInLayout: 'xl' | 'lg' | 'md';
};
type NavSubmenuProps = DefaultProps & {
    items: (NavItemProps | null)[];
    onClick?: () => void;
};

export const NavSubmenu = ({ items, className, onClick }: NavSubmenuProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isLanguageMenuOpen, setIsLanguageMenuOpen] = useState(false);
    const { track } = useMixpanel();

    const node = useRef<HTMLDivElement>(null);
    useOnClickOutside(node, isOpen ? () => handleCloseMenu() : undefined);

    const handleCloseMenu = () => {
        setIsLanguageMenuOpen(false);
        setIsOpen(false);
    };

    const handleLanguageMenuOpen = () => {
        setIsLanguageMenuOpen(true);
    };

    const handleMenuClick = () => {
        setIsOpen(!isOpen);
        onClick && onClick();
    };

    const LanguageMenu = () => (
        <div>
            <BiArrowBack
                className="mb-2 cursor-pointer pointer-events-auto"
                onClick={() => setIsLanguageMenuOpen(false)}
            />
            <LanguageSelector
                onLangSelected={handleCloseMenu}
                isLanguageMenuOpen={isLanguageMenuOpen}
                className={className}
            />
        </div>
    );

    return (
        <div ref={node}>
            <Button name="Menu" variant="secondary" size="icon" onClick={handleMenuClick}>
                <MoreVert fr="true" />
            </Button>
            <div
                className={classNames(
                    'dropdown__container sub-menu w-44 p-2 absolute flex flex-col gap-1 rounded-xl right-0 mt-2',
                    className,
                    { block: isOpen, hidden: !isOpen },
                )}
            >
                {isLanguageMenuOpen ? (
                    <LanguageMenu />
                ) : (
                    items
                        .filter((item) => item !== null)
                        .map((item, index) => (
                            // @ts-expect-error The item is not null, but TS cannot infer that
                            <NavItem
                                key={index}
                                {...item}
                                onNestedMenuClick={
                                    item!.hasNestedMenu
                                        ? handleLanguageMenuOpen
                                        : () => track(`SUBMENU-CLICK-${item?.label}`)
                                }
                            />
                        ))
                )}
            </div>
        </div>
    );
};
