import { ImageAsset } from '@/components/atom/Image';
import { ContractInterface } from 'ethers';
import { NETWORK_CONFIG } from './tokens';
import { ContractRecord, Network, NetworkConfig, Token } from './types';

const ClayGameAbi = () => import('@/utils/contracts/abi/ClayGame.abi.json');

export interface GameConfig {
    tokenName: string;
    tokenAsset: ImageAsset;
    networkAsset: ImageAsset;
    token: Token;
    testnet: NetworkConfig;
    mainnet: NetworkConfig;
    address?: ContractRecord<string>;
    abi?: ContractRecord<() => Promise<{ default: ContractInterface }>>;
}

export const GAME_CONFIG: GameConfig = {
    tokenName: 'Matic',
    tokenAsset: 'matic',
    networkAsset: 'ethereum',
    token: 'MATIC_POLYGON',
    testnet: NETWORK_CONFIG.mumbai,
    mainnet: NETWORK_CONFIG.polygon,
    address: {
        mumbai: { ClayGame: '' },
        polygon: { ClayGame: '' },
    },
    abi: { mumbai: { ClayGame: ClayGameAbi }, polygon: { ClayGame: ClayGameAbi } },
};

export type NFT = {
    id: string;
    network: Network;
    name?: string;
    description?: string;
    image?: string;
    isKey?: boolean;
    balance?: number;
};

export const NFTCollection: NFT[] = [
    {
        id: '0x123',
        network: 'polygon',
        name: 'NFT 1',
        description: 'NFT 1',
        image: 'https://i.imgur.com/1J8ZQYt.png',
        isKey: false,
    },
    {
        id: '0x456',
        network: 'polygon',
        name: 'NFT 2',
        description: 'NFT 2',
        image: 'https://i.imgur.com/1J8ZQYt.png',
        isKey: true,
    },
];

export const getNftInfo = (id: string) => {
    return NFTCollection.find((nft) => nft.id === id);
};
