import { appConfigAtom, appModalManager } from '@/state/app';
import { walletAtom, walletIsValidNetwork } from '@/state/wallet';
import { truncateAddress } from '@/utils/common';
import { getAssetByWallet } from '@/utils/utils';
import { useRouter } from 'next/router';
import { FC, useMemo } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { match } from 'ts-pattern';
import { Button } from '../atom/Button';
import { Image } from '../atom/Image';

interface AptosWalletInfoBoxProps {
    className?: string;
}
export const AptosWalletInfoBox: FC<AptosWalletInfoBoxProps> = (props) => {
    const router = useRouter();
    const isMissionHubPage = router.asPath.includes('/mission-hub');
    const isAptosPage = router.asPath.includes('/aptos');
    const walletState = useRecoilValue(walletAtom);
    const appConfig = useRecoilValue(appConfigAtom);
    const isWalletValidNetwork = useRecoilValue(walletIsValidNetwork);
    const setModal = useSetRecoilState(appModalManager);
    const hasValidNetwork = !!walletState.selectedAddress && (!router.query.slug || isWalletValidNetwork);

    const walletInfoText = useMemo(() => {
        return match(walletState)
            .with({ walletType: 'aptos' }, ({ selectedAddress }) => truncateAddress(selectedAddress, 6, 4, 'APTOS'))
            .otherwise(() => '');
    }, [walletState, appConfig.token]);

    const handleSwitchNetworkClick = async () => {
        setModal('aptos-switch-network');
    };

    const handleConnectClick = async () => {
        setModal('connect');
    };

    return (
        <div className={props.className}>
            {!walletState.selectedAddress ||
                (isMissionHubPage && walletState.walletType === 'aptos') ||
                (isAptosPage && walletState.walletType !== 'aptos' && (
                    <Button
                        name="Connect Wallet"
                        id="connect-wallet-navbar"
                        onClick={handleConnectClick}
                        className="h-full"
                    >
                        Connect Wallet
                    </Button>
                ))}
            {!!walletState.selectedAddress && !hasValidNetwork && !(isAptosPage && walletState.walletType !== 'aptos') && (
                <Button
                    name="Switch Network"
                    id="wrong-network-btn"
                    onClick={handleSwitchNetworkClick}
                    className="h-full"
                    variant="outline-tertiary"
                >
                    Switch Network
                </Button>
            )}
            {((!!walletState.selectedAddress &&
                hasValidNetwork &&
                !(isMissionHubPage && walletState.walletType === 'aptos')) ||
                (isAptosPage &&
                    walletState.walletType === 'aptos' &&
                    hasValidNetwork &&
                    !!walletState.selectedAddress)) && (
                <Button
                    suffix={<Image asset={getAssetByWallet(walletState.walletType!)!} isIcon={true} />}
                    variant="secondary"
                    onClick={handleConnectClick}
                    name="Connect wallet"
                    className="h-full py-3"
                >
                    <div className="w-28 overflow-clip">{walletInfoText}</div>
                </Button>
            )}
        </div>
    );
};
