import { Maybe } from '@/utils/common';
import { getNetworkByToken } from '@/utils/utils';
import { atom, selector } from 'recoil';
import { appConfigAtom } from './app';

export type WalletType = 'metamask' | 'wallet_connect' | 'coinbase' | 'unstoppable_domains' | 'aptos';

type UnstoppableDomainsUser = {
    sub: string;
    wallet_address?: string;
};
export interface WalletAtom {
    walletType: Maybe<WalletType>;
    walletLoading: Maybe<WalletType>;
    selectedAddress?: Maybe<string>;
    chainId: Maybe<number>;
    targetChainId?: Maybe<number>;
    unstoppableDomains: Maybe<UnstoppableDomainsUser>;
}

export const walletAtom = atom<WalletAtom>({
    key: '_wallet',
    default: {
        walletType: null,
        walletLoading: null,
        selectedAddress: null,
        chainId: 0,
        targetChainId: 1,
        unstoppableDomains: null,
    },
});

export const walletIsValidNetwork = selector({
    key: '_wallet.isValidNetwork',
    get: ({ get }) => {
        const appConfig = get(appConfigAtom);
        const walletState = get(walletAtom);
        const validNetwork = getNetworkByToken(appConfig.token).chainId;
        if (appConfig.token === 'APTOS') return walletState.chainId === walletState.targetChainId;
        return validNetwork === walletState.chainId;
    },
});
