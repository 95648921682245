import { useEffect } from 'react';
import { useLocalStorage } from 'react-use';
import { useRecoilState } from 'recoil';
import { match } from 'ts-pattern';

import { LocalStorageKeys } from '@/constants/local-storage';

import { WalletAtom, walletAtom, WalletType } from '@/state/wallet';

import { Maybe } from '@/utils/common';

import { useRouter } from 'next/router';
import { useAptosWalletFunctions } from './aptos';

export const useAptosWalletManager = () => {
    const router = useRouter();
    const isAptosPage = router.asPath.includes('/aptos');
    const [cachedWalletType, setCacheWalletType] = useLocalStorage<Maybe<WalletType>>(
        LocalStorageKeys.WALLET_TYPE,
        null,
    );
    const [walletState, setWalletState] = useRecoilState(walletAtom);
    const { connectAptosWallet, getAptosChainId } = useAptosWalletFunctions();

    useEffect(() => {
        try {
            //To activate wallet after next loads the route. 100 milli is ~ instantaneous
            setTimeout(async () => {
                await match(cachedWalletType)
                    .with(null, () => void 0)
                    .with('metamask', () => void 0)
                    .with('wallet_connect', () => void 0)
                    .with('coinbase', () => void 0)
                    .with('unstoppable_domains', () => void 0)
                    .with('aptos', () => activateAptos())
                    .run();
            }, 1000);
        } catch {}
    }, []);

    const activateAptos = async () => {
        const user = await connectAptosWallet();
        const chainId = await getAptosChainId();
        setWalletState((previous) => ({
            ...previous,
            selectedAddress: user?.address,
            walletType: 'aptos',
            chainId: chainId ?? 0,
            targetChainId: 2,
        }));
    };

    useEffect(() => {
        setCacheWalletType(walletState.walletType);
    }, [walletState.walletType]);
};

export const useAptosWallet = () => {
    const { connectAptosWallet, getAptosChainId, getMartianProvider } = useAptosWalletFunctions();
    const [walletState, setWalletState] = useRecoilState(walletAtom);
    const updateWalletState = (state: Partial<WalletAtom>) => setWalletState((previous) => ({ ...previous, ...state }));

    const activateAptos = async () => {
        if (!getMartianProvider()) {
            window.open('https://www.martianwallet.xyz/', '_blank');
        }
        const user = await connectAptosWallet();
        const chainId = await getAptosChainId();
        setWalletState((previous) => ({
            ...previous,
            walletType: 'aptos',
            selectedAddress: user?.address ?? '',
            chainId: chainId ?? 0,
            targetChainId: 2,
        }));
    };

    const aptosConnectWallet = async (_id: WalletType) => {
        updateWalletState({ walletLoading: _id });
        try {
            switch (_id) {
                case 'aptos':
                    await activateAptos();
            }
        } catch {}
        updateWalletState({ walletLoading: null });
    };

    return {
        aptosConnectWallet,
    };
};
