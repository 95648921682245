import Link from 'next/link';

import { DefaultProps } from '@/utils/common';

export type AnchorProps = DefaultProps<typeof Link> & {
    href: string;
    caption: string;
    className?: string;
    isNextLink?: boolean;
    isExternal?: boolean;
    id?: string;
    onClick?: () => void;
    isNew?: boolean;
};
export const Anchor = ({
    href,
    caption,
    className,
    isNextLink = false,
    isExternal = false,
    id,
    onClick = undefined,
    isNew = false,
}: AnchorProps) => {
    return !isNextLink ? (
        <a
            href={href}
            className={className}
            target={isExternal ? '_blank' : '_self'}
            rel={isExternal ? 'noreferrer' : undefined}
            id={id}
            onClick={onClick}
        >
            {isNew ? (
                <div className="flex flex-col items-end mb-4">
                    <span className="text-xs text-pink-50">New</span>
                    <span className={className} id={id}>
                        {caption}
                    </span>
                </div>
            ) : (
                caption
            )}
        </a>
    ) : isNew ? (
        <div className="flex flex-col items-end mb-4">
            <span className="text-xs text-pink-50">New</span>
            <span className={className} id={id}>
                <Link href={href}>{caption}</Link>
            </span>
        </div>
    ) : (
        <span className={className} id={id}>
            <Link href={href}>{caption}</Link>
        </span>
    );
};
