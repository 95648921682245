import { ModalManager } from '@/components/modals/ModalManager';
import { useAptosWalletManager } from '@/hooks/aptos/aptos-wallet';
import { useRouterGuard } from '@/hooks/router-guard';
import { useAnchorBackground } from '@/hooks/support/custom-styles';
import { useTransactionsManager } from '@/hooks/transactions';
import { useWalletManager } from '@/hooks/wallet';
import { motion } from 'framer-motion';
import { useRouter } from 'next/router';
import { FC, useMemo } from 'react';
import { Footer } from './Footer';
import { Navbar } from './Navbar';
import { HelpCenter } from './organism/HelpCenter';

const variants = {
    hidden: { opacity: 0 },
    enter: { opacity: 1 },
    exit: { opacity: 0 },
};

export const Layout: FC = (props) => {
    useWalletManager();
    useRouterGuard();
    useTransactionsManager();
    // Aptos hooks
    useAptosWalletManager();
    const router = useRouter();
    const { style } = useAnchorBackground();
    const isCustomBgGradient = useMemo(
        () =>
            router.asPath.includes('mission-hub') ||
            router.asPath.includes('stake') ||
            router.asPath.includes('exchange') ||
            router.asPath.includes('market') ||
            router.asPath.includes('pools') ||
            router.asPath.includes('game'),
        [router.asPath],
    );

    return (
        <div className="flex font-body">
            {!isCustomBgGradient ? (
                <div className="fixed top-0 left-0 z-0 w-full h-full">
                    <div className="gr--blue" style={style}>
                        <div className="gr--red"></div>
                    </div>
                </div>
            ) : null}
            <main className="z-10 flex flex-col w-full min-h-screen">
                <ModalManager />
                <Navbar />
                <motion.div
                    variants={variants} // Pass the variant object into Framer Motion
                    initial="hidden" // Set the initial state to variants.hidden
                    animate="enter" // Animated state to variants.enter
                    exit="exit" // Exit state (used later) to variants.exit
                    transition={{ type: 'spring' }}
                >
                    {props.children}
                </motion.div>
                <Footer />
            </main>
            <HelpCenter />
        </div>
    );
};
