import axios from 'axios';
import { parseAddress } from '@/utils/common';

export const instance = axios.create({
    baseURL: process.env.API_URL || 'https://api.claystack.com',
});

export const post = async (endpoint: string, data: any = null) => {
    const response = await instance.post(endpoint, {
        ...data,
        address: data?.address ? parseAddress(data.address) : undefined,
    });
    return response?.data;
};
