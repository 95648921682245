import { isFactory, Size } from '@/utils/common';
import classNames from 'classnames';
import { MouseEventHandler } from 'react';
import { ensuredForwardRef } from 'react-use';

import { Loader } from './Loader';

export type ButtonVariant =
    | 'ghost'
    | 'primary'
    | 'primary-alt'
    | 'primary-mission-hub'
    | 'secondary'
    | 'outline'
    | 'outline-secondary'
    | 'outline-tertiary'
    | 'flat'
    | 'link'
    | 'tertiary'
    | 'negative'
    | 'teal-primary'
    | 'primary-white';
export interface ButtonProps {
    id?: string;
    name?: string; //For SEO
    isLoading?: boolean;
    className?: string;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    onHover?: (state: boolean) => void;
    size?: Size | 'block' | 'icon' | 'x-large' | 'alt-block';
    isDisabled?: boolean;
    suffix?: React.ReactNode;
    prefix?: React.ReactNode;
    isPrefixNonPadding?: boolean;
    variant?: ButtonVariant;
    children?: React.ReactNode;
}
export const Button = ensuredForwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
    const is = isFactory(props);
    const isSize = is('size');
    const isVariant = is('variant');
    const className = classNames([
        'flex items-center transition-colors disabled:pointer-events-none gap-2 relative outline-none whitespace-nowrap',
        {
            'transition-colors text-white bg-main-50 hover:bg-main-60 disabled:bg-grey-50 disabled:text-grey-30 disabled:ring-0 disabled:ring-main-60':
                isVariant('primary'),
            'transition-colors text-black bg-white hover:bg-grey-10 hover:text-main-50': isVariant('primary-white'),
            'transition-colors text-white bg-main-50 hover:bg-main-60 disabled:bg-grey-60 disabled:text-grey-40 disabled:ring-0 disabled:ring-main-60':
                isVariant('primary-mission-hub'),
            'transition-colors text-white bg-main-60 hover:bg-main-50 disabled:bg-grey-50 disabled:text-grey-30 disabled:ring-0 disabled:ring-main-60':
                isVariant('primary-alt'),
            'transition-colors text-white bg-pink-60 hover:bg-pink-50 disabled:bg-grey-50 disabled:text-grey-30 disabled:ring-0 disabled:ring-main-60':
                isVariant('tertiary'),
            'text-main-30 bg-main-70 hover:bg-main-60': isVariant('ghost'),
            'bg-transparent ring-1 hover:bg-main-50/10 text-main-80 ring-main-60 hover:ring-main-70 disabled:text-neutral-30 disabled:ring-neutral-30 disabled:bg-neutral-50':
                isVariant('outline'),
            'bg-transparent ring-1 text-grey-20 ring-grey-30 hover:ring-grey-40 disabled:text-neutral-90 disabled:text-neutral-30 disabled:ring-neutral-30 disabled:bg-neutral-50':
                isVariant('outline-secondary'),
            'bg-violet-70 text-violet-30 ring-1 ring-violet-60 hover:bg-violet-50/10 hover:ring-violet-70':
                isVariant('outline-tertiary'),
            'transition-colors text-red-50 hover:bg-red-20 bg-grey-50 disabled:text-grey-40': isVariant('negative'),
            'transition-colors text-white bg-teal-70 hover:bg-teal-60 disabled:bg-grey-50 disabled:text-grey-30 disabled:ring-0 disabled:ring-teal-70':
                isVariant('teal-primary'),
            'text-grey-20 bg-grey-50 hover:bg-grey-60': isVariant('secondary'),
            'text-main-30 hover:text-main-20': isVariant('link'),
            'bg-grey-50 hover:bg-grey-40/25': isVariant('flat'),
            'px-2 py-1 t__caption--c1 rounded-md': isSize('small'),
            'px-3 py-2 t__button--u1 rounded-lg': isSize('medium'),
            'py-3 px-6 t__button--u1 rounded-lg': isSize('large'),
            'py-4 px-6 t__button--u1 rounded-lg w-40 justify-center': isSize('x-large'),
            'p-2 rounded-md': isSize('icon'),
            'py-4 t__button--u1 rounded-2xl justify-center w-full': isSize('block'),
            'py-2 t__button--u1 rounded-lg justify-center w-full': isSize('alt-block'),
        },
        props.className,
    ]);
    return (
        <button
            className={className}
            disabled={props.isDisabled || props.isLoading}
            onClick={props.onClick}
            onMouseEnter={() => props.onHover && props.onHover(true)}
            onMouseLeave={() => props.onHover && props.onHover(false)}
            id={props.id}
            ref={ref}
            aria-label={props.name}
        >
            {props.isLoading && <Loader className="absolute left-3" color="#d0c6ff" />}
            {props.isPrefixNonPadding ? <div className="absolute left-0">{props.prefix}</div> : props.prefix}
            <div className="pointer-events-none">{props.children}</div>
            {props.suffix}
        </button>
    );
});
Button.defaultProps = {
    size: 'medium',
    isDisabled: false,
    variant: 'primary',
    isLoading: false,
};
