import { useNProgress } from '@tanem/react-nprogress';

type PageLoaderProps = {
    isRouteChanging: boolean;
};

// From: https://github.com/tanem/react-nprogress/blob/master/examples/next-router/components/Loading.tsx
export const PageLoader = ({ isRouteChanging }: PageLoaderProps) => {
    const { animationDuration, isFinished, progress } = useNProgress({
        isAnimating: isRouteChanging,
    });

    return (
        <>
            <style jsx>{`
                .container {
                    opacity: ${isFinished ? 0 : 1};
                    pointer-events: none;
                    transition: opacity ${animationDuration}ms linear;
                }

                .bar {
                    background: hsl(254, 58%, 52%);
                    height: 4px;
                    left: 0;
                    margin-left: ${(-1 + progress) * 100}%;
                    position: fixed;
                    top: 0;
                    transition: margin-left ${animationDuration}ms linear;
                    width: 100%;
                    z-index: 1031;
                }

                .spinner {
                    display: block;
                    height: 100%;
                    opacity: 1;
                    position: absolute;
                    right: 0;
                    transform: rotate(3deg) translate(0px, -4px);
                    width: 100px;
                }
            `}</style>
            <div className="container">
                <div className="bar">
                    <div className="spinner" />
                </div>
            </div>
        </>
    );
};
