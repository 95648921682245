import { appDataAtom, appModalManager } from '@/state/app';
import { walletAtom, walletIsValidNetwork } from '@/state/wallet';
import { areSameAddress } from '@/utils/common';
import { detect } from 'detect-browser';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

const WalletModal = dynamic(import('@/components/wallet/WalletModal'));
const WalletError = dynamic(import('@/components/modals/WalletError'));
const AptosSwitchNetworkModal = dynamic(import('@/components/modals/AptosSwitchNetworkModal'));
const BlockBrowserModal = dynamic(import('@/components/modals/BlockBrowserModal'));
const SwapModal = dynamic(import('@/components/modals/SwapModal'));

export const ModalManager = () => {
    const [modal, setModal] = useRecoilState(appModalManager);
    const router = useRouter();
    const isAptosPage = router.asPath.includes('/aptos');
    const isMissionHubPage = router.asPath.includes('/mission-hub');
    const walletState = useRecoilValue(walletAtom);
    const isWalletValidNetwork = useRecoilValue(walletIsValidNetwork);
    const data = useRecoilValue(appDataAtom);
    const hasValidNetwork = !!walletState.selectedAddress && (!router.query.slug || isWalletValidNetwork);
    const browser = detect();

    const isValidWallet = useMemo(() => {
        const { walletType, walletLoading, selectedAddress, unstoppableDomains } = walletState;
        if (walletLoading) return true;
        if (walletType !== 'unstoppable_domains') return true;
        if (!unstoppableDomains?.wallet_address || !selectedAddress) return true;
        return areSameAddress(unstoppableDomains?.wallet_address, selectedAddress);
    }, [walletState]);

    useEffect(() => {
        if (!isAptosPage) {
            return;
        }
        if (browser?.name === 'firefox' || browser?.name?.includes('edge')) {
            setModal('block-browser');
        }
    }, [browser, isAptosPage]);

    useEffect(() => {
        if (isMissionHubPage && walletState.walletType === 'aptos') {
            setModal('connect');
        } else if (walletState.walletType === 'aptos') {
            setModal(!!walletState.selectedAddress && !hasValidNetwork ? 'aptos-switch-network' : null);
        } else {
            setModal(isValidWallet ? null : 'wallet-error');
        }
    }, [isValidWallet, !!walletState.selectedAddress, hasValidNetwork, isMissionHubPage]);

    useEffect(() => {
        let m = null;
        if (!isValidWallet) {
            m = 'wallet-error';
        } else if (data.csTokenV1Balance?.gt(0)) {
            m = 'swap';
        }
        // @ts-ignore
        setModal(m);
    }, [isValidWallet, data.csTokenV1Balance]);

    const getModal = useMemo(() => {
        switch (modal) {
            case 'block-browser':
                return <BlockBrowserModal isVisible={true} onClose={() => setModal(null)} isClosable={false} />;
            case 'swap':
                return <SwapModal isVisible={true} isClosable={false} balance={data.csTokenV1Balance} />;
            case 'connect':
                return <WalletModal isVisible={true} onClose={() => setModal(null)} />;
            case 'aptos-switch-network':
                return <AptosSwitchNetworkModal isVisible={true} onClose={() => setModal(null)} isClosable={false} />;
            case 'wallet-error':
                return (
                    <WalletError
                        isVisible={true}
                        onClose={() => setModal(null)}
                        userAddress={walletState.unstoppableDomains?.wallet_address ?? ''}
                        domain={walletState.unstoppableDomains?.sub ?? ''}
                    />
                );
            default:
                return null;
        }
    }, [data.csTokenV1Balance, walletState.unstoppableDomains, modal]);

    return <>{getModal}</>;
};
