import { ImageAsset } from '@/components/atom/Image';
import { Deadline } from '@/components/molecule/SelectDeadline';
import { BigNumber, ContractInterface, ethers } from 'ethers';

export type Token =
    | 'MATIC'
    | 'ETH'
    | 'AVAX'
    | 'MATIC_POLYGON'
    | 'CSETH'
    | 'CSMATIC'
    | 'STMATIC'
    | 'MATICX'
    | 'SAVAX'
    | 'CSMATIC_POLYGON'
    | 'STMATIC_POLYGON'
    | 'MATICX_POLYGON'
    | 'APTOS';

export enum Environment {
    mainnet = 'mainnet',
    testnet = 'testnet',
    localhost = 'localhost',
}

export type NetworkFamily = 'ethereum' | 'polygon' | 'aptos' | 'avalanche';

export type Network =
    | 'goerli'
    | 'localhost'
    | 'mainnet'
    | 'mumbai'
    | 'polygon'
    | 'aptos_testnet'
    | 'avalanche'
    | 'fuji';

export type Contract =
    | 'Token'
    | 'CsToken'
    | 'ClayMain'
    | 'ClayManager'
    | 'CsTokenV1'
    | 'ClayExchangeV1'
    | 'ClayExchange'
    | 'ClayGame';

export type Version = 'victoria' | 'malawi' | 'v1';

export type ReadOnlyProvider =
    | ethers.providers.BaseProvider
    | ethers.providers.Web3Provider
    | ethers.providers.JsonRpcProvider;

export type ProviderOrSigner = ethers.Signer | ReadOnlyProvider;

export interface ContractInstance {
    contractName: Contract;
    token: Token;
    network: Network | null;
    version?: Version;
    provider?: ProviderOrSigner;
}

export type ContractRecord<T> = {
    [N in Network]?: {
        [C in Contract]?: T;
    };
};

export type NetworkRecord<T> = {
    [N in Network]?: T;
};

export interface NetworkFamilyConfig {
    name: string;
    icon: {
        asset: ImageAsset;
        width: number;
        height: number;
    };
}

export interface NetworkConfig {
    _id: Network;
    explorer: string;
    chainId: number;
    name: string;
    family: NetworkFamilyConfig;
    currency: string;
}

export interface TokenVocab {
    _id: Token;
    slug: string;
    name: string;
    symbol: string;
    csSymbol: string;
    inTestNet: boolean;
    inMainnet: boolean;
    isChild: boolean;
    isFlashExitEnabled: boolean;
    parentId: Token;
    isNative: boolean;
    styles?: {
        backgroundImage: string;
        backgroundColor: string;
        buttonColor: string;
    };
    isChartEnabled: boolean;
    apr: number;
}

export interface TokenConfig {
    testnet: NetworkConfig;
    mainnet: NetworkConfig;
    vocab: TokenVocab;
    assets: Record<string, ImageAsset>;
    checkpointTime: Record<string, number>;
    cgId: string;
    address: ContractRecord<string>;
    abi: ContractRecord<() => Promise<{ default: ContractInterface }>>;
    version: NetworkRecord<Version>;
}

export type TokenInfo = {
    tvl: BigNumber;
    apy: number;
    usdPrice: number;
};

export interface OrderData {
    _id?: number;
    amount?: BigNumber;
    fee?: number;
    deadline?: Deadline | null;
    allowPartial?: boolean;
    startUnstaking?: boolean | undefined;
    maxValue?: BigNumber;
    minValue?: BigNumber;
    amountLiquidToken?: BigNumber;
    amountTokenReceived?: BigNumber;
}

export interface ExchangeOrder {
    _id: number;
    token: string;
    owner: string;
    amount: BigNumber;
    amountOriginal: BigNumber;
    amountValue: BigNumber;
    amountToFulfill: BigNumber;
    fee: number;
    deadline: number;
    startTime: number;
    createdAt: number;
    allowPartial: boolean;
    status: string;
    tokenName?: string;
    chainId?: number;
    canFulfill?: boolean;
}

export type WithdrawOrder = {
    orderId: number;
    amount: BigNumber;
    fee: BigNumber;
    isClaimed: boolean;
    isClaimable: boolean;
    isEarlyClaimable?: boolean;
    claimTime: number;
    currentTime: number;
    epochsLeft: number;
};
