import { toast, TypeOptions } from 'react-toastify';
import { useList } from 'react-use';

export const useAlerts = () => {
    const [loaderKeys, loaderKeysHandler] = useList();
    const hasLoaderKey = (id: string) => loaderKeys.includes(id);
    const addLoaderKey = (id: string) => loaderKeysHandler.push(id);
    const removeLoaderKey = (id: string) => {
        const index = loaderKeys.indexOf(id);
        loaderKeysHandler.removeAt(index);
    };

    const withKeyAlert = (msg: string, id: string, type: TypeOptions) => {
        if (hasLoaderKey(id)) return;
        addLoaderKey(id);
        toast(msg, {
            type,
            theme: 'dark',
            autoClose: 3000,
            onClose: () => removeLoaderKey(id),
            toastId: id,
        });
    };

    const alertError = (msg: string, id: string = new Date().toString()) => withKeyAlert(msg, id, 'error');
    const alertSuccess = (msg: string, id: string = new Date().toString()) => withKeyAlert(msg, id, 'success');
    const alertInfo = (msg: string, id: string = new Date().toString()) => withKeyAlert(msg, id, 'info');

    return { alertError, alertSuccess, alertInfo };
};
