import { toast } from 'react-toastify';

export interface ProviderRpcError extends Error {
    code: number;
    message: string;
    data?: unknown;
}

export interface ProviderRpcMessageData {
    value: {
        code: string;
        message: string;
    };
}

const ERROR_MESSAGES: Record<string, string> = {
    '4001': 'Please approve the transaction to continue.',
    '-32603': 'Transaction gas underpriced. Please increase gas price.',
};

function titleCase(_str: string) {
    const str = _str.toLowerCase().split(' ');
    for (var i = 0; i < str.length; i++) {
        str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
    }
    return str.join(' ');
}

export const handleError =
    (isSilent = false) =>
    (_error: Error) => {
        try {
            // @ts-expect-error: The error thrown by the provider has some custom objects attached with metadata
            let error = 'error' in _error ? _error.error : _error;
            // @ts-expect-error
            error = 'data' in _error ? _error.data : error;
            const message =
                'reason' in error
                    ? error.reason
                    : error.message.includes('[ethjs-query]')
                    ? handleProviderRpcError(error)
                    : error.message;
            // @ts-expect-error
            const code = 'code' in error ? error.code : _error.code;
            const hasErrorMessage = code in ERROR_MESSAGES;
            let toastMessage = hasErrorMessage ? ERROR_MESSAGES[code] : titleCase(message);

            if (!isSilent) {
                toast.error(toastMessage, { theme: 'dark' });
            }
        } catch {}
        return null;
    };

const handleProviderRpcError = (error: ProviderRpcError) => {
    var formattedErrorString = error.message.substring(error.message.indexOf("'") + 1, error.message.lastIndexOf("'"));
    const messageData = JSON.parse(formattedErrorString) as ProviderRpcMessageData;
    return messageData.value.message.toLocaleUpperCase();
};
