import { ImageAsset } from '@/components/atom/Image';
import { ContractInterface } from 'ethers';
import { NETWORK_CONFIG } from './tokens';
import { ContractRecord, NetworkConfig, Token } from './types';

const ClayExchange = () => import('@/utils/contracts/abi/ClayExchange.abi.json');
const TokenAbi = () => import('@/utils/contracts/abi/Token.abi.json');

export interface ExchangeTokenConfig {
    _id: Token;
    isNative?: boolean;
    isLiquidToken: boolean;
    tokenName: string;
    tokenAsset: ImageAsset;
    networkAsset: ImageAsset;
    primaryToken?: Token;
    cgId?: string;
    testnet: NetworkConfig;
    mainnet: NetworkConfig;
    address?: ContractRecord<string>;
    abi?: ContractRecord<() => Promise<{ default: ContractInterface }>>;
}

export const EXCHANGE_TOKENS: Partial<Record<Token, ExchangeTokenConfig>> = {
    ETH: {
        _id: 'ETH',
        isNative: true,
        isLiquidToken: false,
        tokenName: 'ETH',
        tokenAsset: 'ethereum',
        networkAsset: 'ethereum',
        testnet: NETWORK_CONFIG.goerli,
        mainnet: NETWORK_CONFIG.mainnet,
    },
    MATIC: {
        _id: 'MATIC',
        isNative: false,
        isLiquidToken: false,
        tokenName: 'MATIC',
        tokenAsset: 'matic',
        networkAsset: 'ethereum',
        testnet: NETWORK_CONFIG.goerli,
        mainnet: NETWORK_CONFIG.mainnet,
        address: {
            goerli: {
                Token: '0x499d11e0b6eac7c0593d8fb292dcbbf815fb29ae',
            },
            mainnet: {
                Token: '0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0',
            },
        },
        abi: {
            goerli: { Token: TokenAbi },
            mainnet: { Token: TokenAbi },
        },
    },
    MATIC_POLYGON: {
        _id: 'MATIC_POLYGON',
        isNative: true,
        isLiquidToken: false,
        tokenName: 'MATIC',
        tokenAsset: 'matic',
        networkAsset: 'matic',
        testnet: NETWORK_CONFIG.mumbai,
        mainnet: NETWORK_CONFIG.polygon,
        address: {
            mumbai: {
                ClayExchange: '0x9B8aad2746e94b66f18CF68b4b0E9EA19b9C86eF',
            },
            polygon: {
                ClayExchange: '',
            },
        },
        abi: {
            polygon: { ClayExchange },
            mumbai: { ClayExchange },
        },
    },
    AVAX: {
        _id: 'AVAX',
        isNative: true,
        isLiquidToken: false,
        tokenName: 'AVAX',
        tokenAsset: 'avax',
        networkAsset: 'avax',
        testnet: NETWORK_CONFIG.fuji,
        mainnet: NETWORK_CONFIG.avalanche,
    },
    CSMATIC: {
        _id: 'CSMATIC',
        isLiquidToken: true,
        tokenName: 'csMATIC',
        tokenAsset: 'cs-matic',
        networkAsset: 'ethereum',
        primaryToken: 'MATIC',
        testnet: NETWORK_CONFIG.goerli,
        mainnet: NETWORK_CONFIG.mainnet,
        cgId: 'matic-network',
        address: {
            goerli: {
                Token: '0x3fb4601911871b635011aF01eDda5854F27560ce',
            },
            mainnet: {
                Token: '0x38b7Bf4eeCF3EB530b1529c9401FC37d2a71a912',
            },
        },
        abi: {
            goerli: { ClayExchange, Token: TokenAbi },
            mainnet: { ClayExchange, Token: TokenAbi },
        },
    },
    CSETH: {
        _id: 'CSETH',
        isLiquidToken: true,
        tokenName: 'csETH',
        tokenAsset: 'cs-eth',
        networkAsset: 'ethereum',
        primaryToken: 'ETH',
        testnet: NETWORK_CONFIG.goerli,
        mainnet: NETWORK_CONFIG.mainnet,
        cgId: 'matic-network',
        address: {
            goerli: {
                Token: '0x20581920070F65B6dA60FFc465b2180F01fC857B',
            },
            mainnet: {},
        },
        abi: {
            goerli: { ClayExchange, Token: TokenAbi },
            mainnet: { ClayExchange, Token: TokenAbi },
        },
    },
    STMATIC: {
        _id: 'STMATIC',
        isLiquidToken: true,
        tokenName: 'stMATIC',
        tokenAsset: 'cs-matic',
        networkAsset: 'ethereum',
        primaryToken: 'MATIC',
        testnet: NETWORK_CONFIG.goerli,
        mainnet: NETWORK_CONFIG.mainnet,
        cgId: 'matic-network',
        address: {
            goerli: {},
            mainnet: {
                Token: '0x9ee91F9f426fA633d227f7a9b000E28b9dfd8599',
            },
        },
        abi: {
            goerli: { ClayExchange, Token: TokenAbi },
            mainnet: { ClayExchange, Token: TokenAbi },
        },
    },
    CSMATIC_POLYGON: {
        _id: 'CSMATIC_POLYGON',
        isLiquidToken: true,
        tokenName: 'csMATIC',
        tokenAsset: 'cs-matic',
        networkAsset: 'matic',
        primaryToken: 'MATIC_POLYGON',
        testnet: NETWORK_CONFIG.mumbai,
        mainnet: NETWORK_CONFIG.polygon,
        cgId: 'matic-network',
        address: {
            mumbai: {
                Token: '0x2eD77c504bF34D1d38fd9556D3982dF604f9c3f7',
                ClayExchange: '0x9B8aad2746e94b66f18CF68b4b0E9EA19b9C86eF',
            },
            polygon: {
                Token: '',
                ClayExchange: '',
            },
        },
        abi: {
            polygon: { ClayExchange, Token: TokenAbi },
            mumbai: { ClayExchange, Token: TokenAbi },
        },
    },
    SAVAX: {
        _id: 'SAVAX',
        isLiquidToken: true,
        tokenName: 'sAVAX',
        tokenAsset: 'savax',
        networkAsset: 'avax',
        primaryToken: 'AVAX',
        testnet: NETWORK_CONFIG.fuji,
        mainnet: NETWORK_CONFIG.avalanche,
        cgId: 'avax-network',
        address: {
            fuji: {
                Token: '0x0Abe5E6A70cBa25E4e1F9Be5d2F998610a746eB8',
                ClayExchange: '',
            },
            avalanche: {
                Token: '',
                ClayExchange: '',
            },
        },
        abi: {
            avalanche: { ClayExchange, Token: TokenAbi },
            fuji: { ClayExchange, Token: TokenAbi },
        },
    },
};
