import { ENV_APP_ENV } from '@/constants/env';
import { NETWORK_CONFIG, RPC_URLS, TOKEN_CONFIG } from '@/constants/tokens';
import { Environment, Network, Token } from '@/constants/types';
import { WALLET_LIST } from '@/constants/wallets';
import { WalletType } from '@/state/wallet';
import { ethers } from 'ethers';

export const getNetworkByToken = (token: Token) => {
    switch (ENV_APP_ENV) {
        case Environment.localhost:
            return NETWORK_CONFIG.localhost;
        case Environment.testnet:
            return TOKEN_CONFIG[token]!.testnet;
        default:
            return TOKEN_CONFIG[token]!.mainnet;
    }
};
export const getVocabByToken = (token: Token) => TOKEN_CONFIG[token]!.vocab;
export const getCgIdByToken = (token: Token) => TOKEN_CONFIG[token]!.cgId;
export const getVocabBySlug = (slug: string) => {
    const vocab = Object.values(TOKEN_CONFIG).find((v) => v.vocab.slug === slug)?.vocab;
    return vocab!;
};
export const getAssetBySymbol = (symbol: string) => {
    const config = Object.values(TOKEN_CONFIG).find((v) => symbol in v.assets);
    return config === undefined ? null : config.assets[symbol];
};
export const getAssetBySymbolForIndex = (symbol: string) => {
    const config = Object.values(TOKEN_CONFIG).find((v) => symbol in v.assets);
    return config === undefined ? null : config.assets[symbol] + '-main';
};
export const getNetworkByChainId = (chainId: number | null | undefined) =>
    Object.values(NETWORK_CONFIG).find((network) => network.chainId === chainId)!;

export const isValidChainId = (chainId: number) =>
    Object.values(NETWORK_CONFIG).find((network) => network.chainId === chainId) !== undefined;

export const getBackendProvider = async (network: Network) => {
    const urls = RPC_URLS[network];
    for (const url of urls) {
        try {
            const provider = new ethers.providers.StaticJsonRpcProvider(url);
            await provider._ready();
            return provider;
        } catch (e) {
            console.warn(`FAILED: ${url} - TRYING NEXT RPC`);
        }
    }

    // Returns default
    return ethers.getDefaultProvider(network);
};

export const getAssetByWallet = (walletType: WalletType) =>
    WALLET_LIST.find((w) => w._id === walletType)?.asset ?? null;

export const getAssetByNetwork = (network: Network) => {
    switch (network.toLowerCase()) {
        case 'polygon':
            return '/images/icon__matic.svg';
        case 'mumbai':
            return '/images/icon__matic.svg';
        case 'aptos_testnet':
            return '/images/icon__aptos.svg';
        default:
            return '/images/icon__ethereum.svg';
    }
};

export const getApproxWithdrawTimeInEpochs = (token: Token, network: Network) => {
    let noOfCheckpoints = 0;
    if (token == 'CSMATIC' || token === 'CSMATIC_POLYGON') {
        noOfCheckpoints = 80;
    }
    return `${noOfCheckpoints} epochs`;
};
