import { ExchangeOrder, Token } from '@/constants/types';
import { AptosGetters } from '@/hooks/aptos/aptos';
import { Transaction } from '@/hooks/transactions';
import { isNull, Maybe } from '@/utils/common';
import { defaultStanding } from '@/utils/http/get-standing';
import { RateLimit } from '@/utils/http/twitter';
import { BigNumber } from 'ethers';
import { atom, selector } from 'recoil';
import { walletAtom } from './wallet';
import { NFT } from '@/constants/game-config';

interface AppHelpCenterAtom {
    isOpen: boolean;
    lastSearches: string[];
}

export const appHelpCenterAtom = atom<AppHelpCenterAtom>({
    key: '_app.helpCenter',
    default: {
        isOpen: false,
        lastSearches: [],
    },
});

export type ModalValue =
    | 'connect'
    | 'invites'
    | 'point-system'
    | 'wallet-error'
    | 'aptos-switch-network'
    | 'block-browser'
    | 'swap';
export const appModalManager = atom<Maybe<ModalValue>>({
    key: '_app.modalManager',
    default: null,
});

interface AppConfigAtom {
    token: Token;
    isWhitelisted?: boolean | undefined;
}

export const appConfigAtom = atom<AppConfigAtom>({
    key: '_app.config',
    default: {
        token: 'CSMATIC',
    },
});

export enum Events {
    stakeEvent = 'stakeEvent',
    unstakeEvent = 'unstakeEvent',
    flashExitEvent = 'flashExitEvent',
    claimEvent = 'claimEvent',
    claimEarlyEvent = 'claimEarlyEvent',
    allowEvent = 'allowEvent',
    transactionEvent = 'transactionEvent',
    updateValuesEvent = 'updateValuesEvent',
    refreshPoints = 'refreshPoints',
    csTokenV2SwapEvent = 'csTokenV2SwapEvent',
    csTokenV2AllowEvent = 'csTokenV2AllowEvent',
    orderCreateEvent = 'orderCreateEvent',
    orderUpdateEvent = 'orderUpdateEvent',
    orderCancelEvent = 'orderCancelEvent',
    orderClaimEvent = 'orderClaimEvent',
    orderFulfillEvent = 'orderFulfillEvent',
    gameMintEvent = 'gameMintEvent',
}

export const appEventsAtom = atom<Record<Events, number>>({
    key: '_app.events',
    default: {
        stakeEvent: 0,
        unstakeEvent: 0,
        flashExitEvent: 0,
        claimEvent: 0,
        claimEarlyEvent: 0,
        allowEvent: 0,
        transactionEvent: 0,
        updateValuesEvent: 0,
        refreshPoints: 0,
        csTokenV2AllowEvent: 0,
        csTokenV2SwapEvent: 0,
        orderCreateEvent: 0,
        orderUpdateEvent: 0,
        orderCancelEvent: 0,
        orderClaimEvent: 0,
        orderFulfillEvent: 0,
        gameMintEvent: 0,
    },
});

export const appPointsAtom = atom({
    key: '_app.points',
    default: {
        isLoading: true,
        standingData: defaultStanding,
        lastUpdate: null as Maybe<Date>,
        twitterLimit: {} as RateLimit,
    },
});

export const appSignatureAtom = atom({
    key: '_app.signature',
    default: {
        address: null,
        signature: null,
    },
});

interface AppDataAtom {
    instantWithdrawFee: BigNumber;
    withdrawFee: BigNumber;
    depositFee: BigNumber;
    rewardFee: BigNumber;
    earlyClaimFee: BigNumber;
    exchangeRate: BigNumber;
    instantWithdrawalLimit: BigNumber;
    withdrawLimit: BigNumber;
    tokenBalance: BigNumber;
    tokenAllowance: BigNumber;
    csTokenBalance: BigNumber;
    csTokenAllowance: BigNumber;
    csTokenV1Balance: BigNumber;
    csTokenV1Allowance: BigNumber;
    csTokenClayExchangeAllowance: BigNumber;
    claimableAmount: BigNumber;
    defaultLiquidity: BigNumber;
    swapExchangeRate: BigNumber;
    isDataLoading: boolean;
    isBalanceLoading: boolean;
    isClaimsLoading: boolean;
}

export const appDataAtom = atom<AppDataAtom>({
    key: '_app.data',
    default: {
        instantWithdrawFee: BigNumber.from(0),
        withdrawFee: BigNumber.from(0),
        depositFee: BigNumber.from(0),
        rewardFee: BigNumber.from(0),
        earlyClaimFee: BigNumber.from(0),
        exchangeRate: BigNumber.from(0),
        instantWithdrawalLimit: BigNumber.from(0),
        withdrawLimit: BigNumber.from(0),
        tokenBalance: BigNumber.from(0),
        tokenAllowance: BigNumber.from(0),
        csTokenBalance: BigNumber.from(0),
        csTokenAllowance: BigNumber.from(0),
        csTokenV1Balance: BigNumber.from(0),
        csTokenV1Allowance: BigNumber.from(0),
        csTokenClayExchangeAllowance: BigNumber.from(0),
        claimableAmount: BigNumber.from(0),
        defaultLiquidity: BigNumber.from(0),
        swapExchangeRate: BigNumber.from(0),
        isDataLoading: false,
        isBalanceLoading: false,
        isClaimsLoading: false,
    },
});

export const appTransactionsAtom = atom<Transaction[]>({
    key: '_app.transactions',
    default: [],
});

export const userTransactionsAtom = selector({
    key: '_app.userTransactions',
    get: ({ get }) => {
        const metamask = get(walletAtom);
        const list = get(appTransactionsAtom);
        if (isNull(metamask.selectedAddress)) return list;
        return list.filter(
            (l) =>
                l.address.toLocaleLowerCase() === metamask.selectedAddress?.toLocaleLowerCase() &&
                l.chainId === metamask.chainId,
        );
    },
});

export const aptosDataAtom = atom<Partial<AptosGetters>>({
    key: '_app.aptosDataAtom',
    default: {},
});

export interface TokenInfo {
    balance: BigNumber;
    allowance: BigNumber;
    exchangeRate: BigNumber;
}

export interface ExchangeDataAtom {
    tokens: Partial<Record<Token, TokenInfo>>;
    splitFee: BigNumber | undefined;
    update: number;
}

export const exchangeDataAtom = atom<ExchangeDataAtom>({
    key: '_exchange.data',
    default: {
        tokens: {},
        splitFee: undefined,
        update: 0,
    },
});

export const marketOrdersAtom = atom({
    key: '_app.marketOrders',
    default: {
        orders: {} as Record<number, ExchangeOrder>,
        updateAPI: 0,
        update: 0,
    },
});

export const gameDataAtom = atom({
    key: '_app.game',
    default: {
        next_claim: undefined as number | undefined,
        nfts: [] as NFT[],
    },
});
