import { UAuthConnector } from '@uauth/web3-react';
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core';
import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { WalletLinkConnector } from '@web3-react/walletlink-connector';
import { Signer } from 'ethers';
import { useEffect } from 'react';
import { useLocalStorage } from 'react-use';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { match } from 'ts-pattern';
import { WalletLinkProvider } from 'walletlink';

import { ENV_APP_ENV } from '@/constants/env';
import { LocalStorageKeys } from '@/constants/local-storage';
import { RPC_URLS } from '@/constants/tokens';
import { Network, Token } from '@/constants/types';

import { useAlerts } from '@/hooks/alerts';
import { useMixpanel } from '@/hooks/mixpanel/mixpanel';

import { appModalManager } from '@/state/app';
import { WalletAtom, walletAtom, WalletType } from '@/state/wallet';

import { hexToInt, intToHex, isNull, Maybe } from '@/utils/common';
import { coinbaseConnector, metamaskConnector, uauthConnector, walletConnectConnector } from '@/utils/connectors';
import { handleError, ProviderRpcError } from '@/utils/error';
import { getNetworkByChainId, getVocabByToken } from '@/utils/utils';
import { getAddress } from '@/utils/ether';

import { useRouter } from 'next/router';
import { useTranslation } from './support/translate';

export interface AddTokenOptions {
    address: string;
    symbol: string;
    decimals: number;
    image?: string;
}

interface WalletConnectProvider {
    connected: boolean;
    isConnecting: boolean;
    enable: () => void;
    qrcodeModal: {
        open: () => void;
        close: () => void;
    };
}

type Web3ReactChainEvent = {
    chainId: number;
};
type Web3ReactAccountEvent = {
    account: string;
};
type Web3ReactUpdateEvent = Web3ReactChainEvent | Web3ReactAccountEvent;

type WalletConnectors = InjectedConnector | WalletConnectConnector | WalletLinkConnector | UAuthConnector;

const isChainChangeEvent = (e: Web3ReactUpdateEvent): e is Web3ReactChainEvent => 'chainId' in e;
const isAccountsChangeEvent = (e: Web3ReactUpdateEvent): e is Web3ReactAccountEvent => 'account' in e;

const parseChainId = (chainId: number | string) => (typeof chainId === 'string' ? hexToInt(chainId) : chainId);

const getWalletByConnector = (connector: WalletConnectors | undefined, url?: string) => {
    let output = null;
    if (connector instanceof InjectedConnector && url === 'metamask') {
        output = 'metamask';
    } else if (connector instanceof WalletConnectConnector) {
        output = 'wallet_connect';
    } else if (connector instanceof WalletLinkConnector) {
        output = 'coinbase';
    } else if (connector instanceof UAuthConnector) {
        output = 'unstoppable_domains';
    }
    return output as WalletType;
};

export const useWalletManager = () => {
    const { mixpanel, track } = useMixpanel();
    const router = useRouter();
    const isAptosPage = router.asPath.includes('/aptos');
    const [cachedWalletType, setCacheWalletType] = useLocalStorage<Maybe<WalletType>>(
        LocalStorageKeys.WALLET_TYPE,
        null,
    );
    const [walletState, setWalletState] = useRecoilState(walletAtom);
    const { connector, library, activate } = useWeb3React();

    const refreshUAuth = async () => {
        const uAuthUser = await uauthConnector.uauth.user().catch(() => null);
        setWalletState((previous) => ({
            ...previous,
            unstoppableDomains: uAuthUser,
        }));
    };

    useEffect(() => {
        try {
            //To activate wallet after next loads the route. 100 milli is ~ instantaneous
            setTimeout(async () => {
                await match(cachedWalletType)
                    .with(null, () => void 0)
                    .with('metamask', () => activate(metamaskConnector))
                    .with('wallet_connect', () => activate(walletConnectConnector))
                    .with('coinbase', () => activate(coinbaseConnector))
                    .with('unstoppable_domains', () => activate(uauthConnector))
                    .with('aptos', () => void 0)
                    .run();
                const isUAuth = cachedWalletType === 'unstoppable_domains';
                if (isUAuth) {
                    await refreshUAuth();
                }
            }, 1000);
        } catch {}
    }, []);

    const handleWeb3ReactUpdate = (event: Web3ReactUpdateEvent) => {
        if (isAccountsChangeEvent(event)) {
            mixpanel.identify(event.account);
            const isUAuth = cachedWalletType === 'unstoppable_domains';
            if (isUAuth) refreshUAuth();
            setWalletState((previous) => ({ ...previous, selectedAddress: event.account }));
        }
        if (isChainChangeEvent(event)) {
            setWalletState((previous) => ({
                ...previous,
                chainId: parseChainId(event.chainId),
            }));
        }
    };

    const handleWeb3ReactDeactivate = () => {
        setWalletState((previous) => ({
            ...previous,
            selectedAddress: null,
            chainId: null,
            walletType: null,
        }));
    };

    useEffect(() => {
        if (isAptosPage) return;
        if (connector === undefined) return;
        (async () => {
            const selectedAddress = (await connector.getAccount()) ?? null;
            const chainId = parseChainId(await connector.getChainId());

            const walletType = getWalletByConnector(connector as WalletConnectors, library?.connection?.url);

            if (!isNull(selectedAddress)) {
                mixpanel.identify(selectedAddress);
                mixpanel.people.union('App version', ENV_APP_ENV);
                track('Connect wallet', {
                    'Wallet name': walletType,
                    Network: getNetworkByChainId(chainId)?.name,
                });
            }

            setWalletState((previous) => ({
                ...previous,
                selectedAddress: selectedAddress,
                chainId,
                walletType,
            }));
        })();

        connector.on('Web3ReactUpdate', handleWeb3ReactUpdate);
        connector.on('Web3ReactDeactivate', handleWeb3ReactDeactivate);

        return () => {
            connector.off('Web3ReactUpdate', handleWeb3ReactUpdate);
            connector.off('Web3ReactDeactivate', handleWeb3ReactDeactivate);
        };
    }, [connector, library, isAptosPage]);

    useEffect(() => {
        setCacheWalletType(walletState.walletType);
    }, [walletState.walletType]);
};

export const useWallet = () => {
    const { alertError } = useAlerts();
    const { t } = useTranslation();
    const web3React = useWeb3React();
    const { connector, library, active, activate } = web3React;
    const [walletState, setWalletState] = useRecoilState(walletAtom);
    const setModal = useSetRecoilState(appModalManager);
    const updateWalletState = (state: Partial<WalletAtom>) => setWalletState((previous) => ({ ...previous, ...state }));

    const connectWallet = async (_id: WalletType) => {
        updateWalletState({ walletLoading: _id });
        try {
            switch (_id) {
                case 'metamask':
                    await activate(metamaskConnector, handleError());
                    break;
                case 'wallet_connect':
                    const provider: WalletConnectProvider | undefined = walletConnectConnector.walletConnectProvider;

                    const isConnecting = provider && !provider.connected && provider.isConnecting;

                    if (isConnecting) {
                        walletConnectConnector.walletConnectProvider = undefined;
                    }
                    if (provider?.connected) {
                        await walletConnectConnector.close();
                    }

                    await activate(walletConnectConnector, async (e) => {
                        const isUnsupportedChain = e instanceof UnsupportedChainIdError;
                        if (isUnsupportedChain) {
                            alertError(t('error.networkSupport'));
                            await walletConnectConnector.close();
                        }
                    });
                    break;
                case 'coinbase':
                    const providerCoinBase: WalletLinkProvider | undefined = await coinbaseConnector.getProvider();

                    if (providerCoinBase?.connected) {
                        await coinbaseConnector.close();
                    }

                    await activate(coinbaseConnector, handleError());
                case 'unstoppable_domains':
                    await activate(uauthConnector, handleError());
                    const uAuthUser = await uauthConnector.uauth.user().catch(() => null);
                    setWalletState((previous) => ({
                        ...previous,
                        unstoppableDomains: uAuthUser,
                    }));
            }
        } catch {}
        updateWalletState({ walletLoading: null });
    };

    const disconnectWallet = async (_id: WalletType) => {
        switch (_id) {
            case 'wallet_connect':
                setWalletState((previous) => ({
                    ...previous,
                    selectedAddress: null,
                    chainId: null,
                    walletType: null,
                }));
                await ((connector ?? walletConnectConnector) as WalletConnectConnector).close();
                break;
        }
    };

    const switchNetwork = async (chainId: number) => {
        if (connector instanceof InjectedConnector) {
            const provider = library.provider;
            const canAddChain = await provider!
                .request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: intToHex(chainId) }],
                })
                .then(() => false)
                .catch((err: ProviderRpcError) => {
                    /**
                     * Code 4902 means the chain configuration does not exist in metamask
                     * and can be added to metamask with the transaction below
                     * @see https://docs.metamask.io/guide/rpc-api.html#wallet-switchethereumchain
                     */
                    if (err.code === 4902) {
                        return true;
                    }
                    handleError(true);
                    return false;
                });
            /**
             *  In case the network is not available in the wallet, this adds the network
             *  with config.
             *  @see https://docs.metamask.io/guide/rpc-api.html#wallet-addethereumchain
             */
            if (!canAddChain) return;
            const network = getNetworkByChainId(chainId);
            await provider!.request!({
                method: 'wallet_addEthereumChain',
                params: [
                    {
                        chainId: intToHex(chainId),
                        rpcUrls: RPC_URLS[network._id],
                        chainName: network.name,
                        nativeCurrency: {
                            name: network.currency,
                            symbol: network.currency,
                            decimals: 18,
                        },
                    },
                ],
            }).catch(handleError());
        } else {
            setModal('connect');
        }
    };

    const watchAsset = async (options: AddTokenOptions) => {
        if (!(connector instanceof InjectedConnector)) return;

        const provider = library.provider;
        await provider!.request!({
            method: 'wallet_watchAsset',
            params: {
                type: 'ERC20',
                options,
            },
        }).catch(handleError());
    };

    const addToken = async (token: Token, network: Network) => {
        if (!(connector instanceof InjectedConnector)) return;
        const tokenAddress = getAddress({ token, network, contractName: 'CsToken' });
        const tokenSymbol = getVocabByToken(token)?.csSymbol || 'csToken';
        await watchAsset({
            address: tokenAddress!,
            symbol: tokenSymbol,
            decimals: 18,
        });
    };

    const signMessage = async (message: string) => {
        const provider = library.provider;

        const response = await provider.send('personal_sign', [message, walletState.selectedAddress]).catch(() => null);

        return isNull(response) ? null : (response.result as string);
    };

    return {
        walletState,
        active,
        provider: library,
        signer: library?.getSigner() as Signer,
        connectWallet,
        disconnectWallet,
        switchNetwork,
        addToken,
        watchAsset,
        signMessage,
    };
};
